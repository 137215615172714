import React, { useState } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";
import DropDownV2 from "../DropDownBox/DropdownV2";
import { getClient } from "../../redux/slices/opportunitiesdata";

const UpdateClientForm = ({ onSubmit, onClose }) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.opportunities);

  const handleSearchChange = (newSearchTerm) => {
    dispatch(getClient(newSearchTerm, "professional"));
  };

  const handleFormClick = (e) => {
    e.stopPropagation(); // Stops the click event from bubbling up to the parent div
  };

  const initialValues = {
    clientID: "",
    clientName: "",
    reason: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UpdateClientSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmit(values);
      onClose();
      console.log(values);
    },
  });

  const { getFieldProps, touched, errors, handleSubmit } = formik;

  return (
    <div className={styles.backGround}>
      <div className={styles.form} style={{ width: "500px" }}>
        <div
          className={styles.form}
          style={{ width: "500px" }}
          onClick={handleFormClick}
        >
          <div className={styles.momentTitle}>MOMENT</div>
          <div className={styles.sectionTitle}>Update Client</div>

          <FormikProvider value={formik} handleSubmit={handleSubmit}>
            <Form autoComplete="off" noValidate style={{ width: "100%" }}>
              <Grid container sx={{ width: "100%" }}>
                <Grid item xs={4} sx={{ pr: 3 }}>
                  <p className={styles.dataTitleV2}>Client:</p>
                </Grid>
                <Grid item xs={8} sx={{ mt: 1.5 }}>
                  <DropDownV2
                    options={client}
                    initialSelectedValue={formik.values.clientName}
                    placeholder={"Select Client"}
                    onValueChange={(newValue) => {
                      formik.setFieldValue("clientName", newValue.label);
                      formik.setFieldValue("clientID", newValue.value);
                    }}
                    onSearchChange={handleSearchChange}
                    PopUp={true}
                    searchable={true}
                    addNew={true}
                    errorState={Boolean(touched.clientName && errors.clientName)}
                    clientType="professional"
                  />
                </Grid>
              </Grid>

              <Grid container sx={{ width: "100%" }}>
                <Grid item xs={4} sx={{ pr: 3 }}>
                  <p className={styles.dataTitleV2}>Reason:</p>
                </Grid>
                <Grid item xs={8} sx={{ mt: 1 }}>
                  <TextField
                    fullWidth
                    multiline
                    value={formik.values.reason}
                    type="text"
                    onChange={(e) =>
                      formik.setFieldValue("reason", e?.target?.value)
                    }
                    rows={2}
                    placeholder="Enter reason for update"
                    sx={textfieldStyle}
                    error={Boolean(touched.reason && errors.reason)}
                  />
                </Grid>
              </Grid>

              <div className={styles.updateAndCancel}>
                <div
                  className={styles.updateButton}
                  type="submit"
                  onClick={handleSubmit}
                >
                  <div className={buttonStyles.YesButtonComponentStyling}>
                    SUBMIT
                  </div>
                </div>
                <div className={styles.cancelButton} onClick={onClose}>
                  <div className={buttonStyles.NoButtonComponentStyling}>
                    CANCEL
                  </div>
                </div>
              </div>

            </Form>
          </FormikProvider>
          <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
        </div>
      </div>
    </div>
  );
};

const UpdateClientSchema = Yup.object().shape({
  clientName: Yup.string().required("Client name is required"),
  reason: Yup.string().required("Reason for update is required"),
});

export default UpdateClientForm;