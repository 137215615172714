import React, { useEffect, useState, useRef } from 'react';
import styles from './style.module.css';

function CommentBox({
  initialData = "", // Default to an empty string if initialData is not provided
  onValueChange,
  invalid = false,
  liningColour = '',
  locked = false,
  placeholder = '',
  integerOnly = false
}) {
  const [comment, setComment] = useState(initialData);
  const [isInvalid, setIsInvalid] = useState(invalid);
  const [isFocused, setIsFocused] = useState(false);
  const [color, setColor] = useState(liningColour);
  const commentRef = useRef(null);

  useEffect(() => {
    setComment(initialData); // Ensure initialData is used when the component mounts or updates
  }, [initialData]);

  const handleTextChange = (e) => {
    const value = e.target.value;

    if (integerOnly) {
      const floatValue = parseFloat(value);
      setComment(!isNaN(floatValue) ? floatValue.toString() : '');
    } else {
      setComment(value);
    }

    if (onValueChange) {
      onValueChange(value);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    setIsInvalid(invalid);
    if (invalid) {
      const timerId = setTimeout(() => {
        setIsInvalid(false);
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [invalid]);

  const commentClass = `${styles.Comment} ${isInvalid ? styles.invalidInput : ''}`;

  return integerOnly ? (
    <input
      ref={commentRef}
      type="text" // Using "text" type to avoid spinner on number inputs
      inputMode="decimal" // Ensures mobile keyboards show decimal input
      pattern="[0-9]*[.]?[0-9]*" // Allows integers and floats (e.g., 12, 12.34, .5)
      className={commentClass}
      id="FormComment"
      placeholder={placeholder}
      readOnly={locked}
      value={comment}
      onChange={(e) => {
        const value = e.target.value;

        if (!value || value.match(/^\d*\.?\d*$/)) {
          setComment(value); // Allow intermediate float input (e.g., "12.")
        }

        if (onValueChange) {
          onValueChange(value);
        }
      }}
      style={{ borderColor: liningColour, height: '25px' }}
    />
  ) : (
    <textarea
      ref={commentRef}
      className={commentClass}
      id="FormComment"
      placeholder={placeholder}
      readOnly={locked}
      value={comment}
      onChange={handleTextChange}
      style={{ borderColor: liningColour }}
    />
  );
}

export default CommentBox;
