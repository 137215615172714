import React from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "../../redux/store";
import Dropdown from '../DropDownBox/Dropdown';
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";

const SupplierForm = ({ Name, onSubmit, onClose }) => {
  const { serviceOptions } = useSelector((state) => state.suppliers);

  // Make a copy of suppliers and add N/A at the beginning
  const modifiedSuppliers = [...serviceOptions];

  const handleFormClick = (e) => {
    e.stopPropagation(); // Stops the click event from bubbling up to the parent div
  };

  const initialValues = {
    company: Name,
    contactName: "",
    email: "",
    website: "",
    preferred: "",
    phoneNumber: "",
    services: "",
    comment: "",
    type: "",
    hourlyRate: "",
    initials: "",
  };

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company is required"),
    contactName: Yup.string().required("Contact Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    type: Yup.string().required("Type is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmit(values);
      onClose();
      console.log(values);
    },
  });
  const { getFieldProps, touched, errors, handleSubmit } = formik;

  return (
    <div
      className={styles.backGround}
      style={{ position: "fixed", alignItems: "flex-start" }}
    >
      <div
        className={styles.form}
        style={{ width: "500px" }}
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>New Supplier</div>

        <FormikProvider value={formik} handleSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Company: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.company}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("company", e?.target?.value)
                  }
                  placeholder="Company"
                  sx={textfieldStyle}
                  error={Boolean(touched.company && errors.company)}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Contact Name: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.contactName}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("contactName", e?.target?.value)
                  }
                  placeholder="Contact Name"
                  sx={textfieldStyle}
                  error={Boolean(touched.contactName && errors.contactName)}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Email: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.email}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("email", e?.target?.value)
                  }
                  placeholder="Email Name"
                  sx={textfieldStyle}
                  error={Boolean(touched.email && errors.email)}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Website: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.website}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("website", e?.target?.value)
                  }
                  placeholder="Website"
                  sx={textfieldStyle}
                />
              </Grid>
            </Grid>


            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Preffered:
                </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1.5 }}>
                <Dropdown
                  options={['Use them, preferred', 'Use them, not preferred', "Don't use them"]}
                  initialSelectedValue={formik.values.suppliers}
                  placeholder={"Select Preference"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue("preferred", newValue);
                  }}
                  PopUp={true}
                  errorState={Boolean(
                    touched.preferred && errors.preferred
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Phone Number: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.phoneNumber}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("phoneNumber", e?.target?.value)
                  }
                  placeholder="Phone Number"
                  sx={textfieldStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Type: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <Dropdown
                  options={["Supplier", "Subcontractor"]}
                  initialSelectedValue={formik.values.type}
                  placeholder={"Select Type"}
                  onValueChange={(newValue) =>
                    formik.setFieldValue("type", newValue)
                  }
                  PopUp={true}
                  errorState={Boolean(
                    touched.type && errors.type
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Hourly Rate: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.hourlyRate}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("hourlyRate", e?.target?.value)
                  }
                  placeholder="Hourly Rate"
                  sx={textfieldStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Initials: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.initials}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("initials", e?.target?.value)
                  }
                  placeholder="Initials"
                  sx={textfieldStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Services:{" "}
                </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1.5 }}>
                <Dropdown
                  options={modifiedSuppliers}
                  initialSelectedValue={formik.values.services}
                  placeholder={"Select Services"}
                  onValueChange={(newValue) =>
                    formik.setFieldValue("services", newValue)
                  }
                  mode='multi'
                  PopUp={true}
                  errorState={Boolean(
                    touched.services && errors.services
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Comment:{" "}
                </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.comment}
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue("comment", e?.target?.value)
                  }
                  rows={2}
                  placeholder="Comment"
                  sx={textfieldStyle}
                  //   textareaStyle={textfieldStyle}
                  inputProps={{ style: { margin: 0 } }}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>

        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={handleSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>SUBMIT</div>
          </div>
          <div className={styles.cancelButton} onClick={onClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
      </div>
    </div>
  );
};

export default SupplierForm;
