// DateRangePickerComponent.js

import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Calendar from "../../assets/Calendar.svg";
import styles from "./style.module.css";

import { enGB } from "date-fns/locale";

const DatePicker = ({
  initialDate,
  onDatesChange,
  keepEmpty = false,
  onlyIcon = false,
  visableIcon = false,
  mondayOnly = false,
  inputHeight = "24px", // default height
  borderThickness = "1px", // default border thickness
  borderColor = "#3C96D4", // default border color
  oppCard = false,
  disabled = false,
}) => {
  const [state, setState] = useState([]);
  console.log(disabled, "disableddisabled");
  useEffect(() => {
    let parsedDate;

    if (keepEmpty) {
      parsedDate = null;  // Keep the date empty if keepEmpty is true
    } else {
      if (initialDate) {
        parsedDate = new Date(initialDate.split("/").reverse().join("-"));
        if (isNaN(parsedDate.getTime())) {
          parsedDate = null;  // Handle invalid dates gracefully
        }
      } else {
        parsedDate = new Date();  // Fallback to the current date if initialDate is null
      }
    }

    // Construct the dates object for the DatePicker
    const dates = [
      {
        startDate: parsedDate,
        endDate: parsedDate,
        key: "selection",
      },
    ];

    setState(dates);
  }, [initialDate, keepEmpty]);

  const [isOpen, setIsOpen] = useState(false);

  const handleRangeChange = (item) => {
    console.log("[item.selection])", [item.selection]);

    // If mondayOnly is true, only process changes for Mondays
    if (mondayOnly && !isMonday(item.selection.startDate)) {
      return;
    }

    setState([item.selection]);

    const localStartDate = new Date(
      item.selection.startDate.getTime() -
      item.selection.startDate.getTimezoneOffset() * 60000
    ).toISOString();
    const localEndDate = new Date(
      item.selection.endDate.getTime() -
      item.selection.endDate.getTimezoneOffset() * 60000
    ).toISOString();

    onDatesChange({
      startDate: localStartDate,
      endDate: localEndDate,
    });

    setIsOpen(false);
  };

  // Function to format the date or month based on mondayOnly
  const formatDateOrMonth = (date) => {
    // Convert the date to local date string
    const localDate = new Date(
      date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );

    const day = localDate.getDate();
    const month = localDate.getMonth() + 1;
    const year = localDate.getFullYear();

    // If mondayOnly is true, format to not show the date
    if (mondayOnly) {
      return ``;
    } else {
      // Regular format with full date
      return `${day}/${month}/${year}`;
    }
  };

  // Function to check if a date is Monday
  const isMonday = (date) => {
    return date.getDay() === 1;
  };

  // Function to disable non-Monday dates if mondayOnly is true
  const dayBlocker = (date) => {
    return mondayOnly && !isMonday(date);
  };

  return (
    <>
      {onlyIcon ? (
        <div>
          {oppCard ? (
            <img
              src={Calendar}
              alt="CalendarIcon"
              onClick={!disabled ? () => setIsOpen(true) : undefined}
              className={styles.cardCalendarIcon}
              style={{
                position: "relative",
                filter: disabled ? 'grayscale(100%) opacity(0.5)' : 'none',
                cursor: disabled ? 'not-allowed' : 'pointer',
              }}
            />
          ) : (
            <img
              src={Calendar}
              alt="CalendarIcon"
              onClick={!disabled ? () => setIsOpen(true) : undefined}
              className={styles.CalendarIcon}
              style={{
                position: "relative",
                filter: disabled ? 'grayscale(100%) opacity(0.5)' : 'none',
                cursor: disabled ? 'not-allowed' : 'pointer',
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.datePicker}>
          <div className={styles.inputContainer}>
            <input
              className={styles.inputDateLeaveProfile}
              onClick={!disabled ? () => setIsOpen(true) : undefined}
              value={
                state[0]?.startDate && state[0]?.endDate
                  ? formatDateOrMonth(state[0].startDate)
                  : keepEmpty
                    ? "No Date Selected"
                    : ""
              }
              readOnly
              style={{
                height: inputHeight,
                border: `${borderThickness} solid ${borderColor}`,
                backgroundColor: disabled ? 'lightgray' : '#fff',
                cursor: disabled ? 'not-allowed' : 'pointer',
              }}
            />
            {visableIcon && (
              <div
                className={styles.iconContainer}
                style={{
                  position: "absolute",
                  left: '100%',
                  top: "60%",
                  zIndex: "99",
                  transform: "translateY(-50%)",
                }}>
                <img
                  className={styles.calendarIcon}
                  src={Calendar}
                  alt="CalendarIcon"
                  onClick={!disabled ? () => setIsOpen(true) : undefined}
                  style={{
                    marginTop: mondayOnly ? "5px" : "3px",
                    filter: disabled
                      ? 'grayscale(100%) opacity(0.5)'
                      : mondayOnly
                        ? "brightness(0) saturate(100%) invert(54%) sepia(81%) saturate(1112%) hue-rotate(178deg) brightness(89%) contrast(85%)"
                        : "none",
                    cursor: disabled ? 'not-allowed' : 'pointer',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {/* DatePicker modal */}
      {isOpen && !disabled && (
        <div
          onClick={() => setIsOpen(false)}
          className={styles.backgroundOfDatePicker}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={styles.datePickerDiv}
          >
            <DateRangePicker
              onChange={handleRangeChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={state}
              direction="horizontal"
              staticRanges={[]}
              disabledDay={dayBlocker}
              firstDayOfWeek={1}
              locale={enGB}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DatePicker;
