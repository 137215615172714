import React, { useEffect } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Grid, TextField } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";
import Dropdown from '../DropDownBox/Dropdown';

const TaskDetailsForm = ({ projectData, onSubmit, onClose }) => {
    const statusOptions = ['S3 Prep', 'S3 End', 'S4 Prep', 'S4 End', 'Finish'];
    const costRankingOptions = ['A - Profitable', 'B - Broke Even', 'C - Lost money'];
    const timeRankingOptions = ['A - Ahead of the deadline', 'B - Met the deadline', 'C - Late'];
    const qualityRankingOptions = ['A - Very Satisfied', 'B - There were comments', 'C - Not good enough'];

    const handleFormClick = (e) => {
        e.stopPropagation();
    };

    const initialValues = {
        ID: projectData?.ID || "",
        projectNumber: projectData?.projectNumber || "",
        runner: projectData?.runner || "",
        taskOutsourced: projectData?.taskOutsourced || "",
        projectAddress: projectData?.projectAddress || "",
        status: projectData?.status || "",
        feeEarnt: projectData?.totalFeeEarnt || "",
        internalCost: projectData?.totalInternalCost || "",
        subcontractorPaid: projectData?.subcontractorPaid || "",
        wasSuccessful: projectData?.wasSuccessful || "",
        costRanking: projectData?.costRanking || "",
        timeRanking: projectData?.timeRanking || "",
        qualityRanking: projectData?.qualityRanking || "",
        finalComment: projectData?.finalComment || "",
    };

    const validationSchema = Yup.object().shape({
        status: Yup.string(),
        feeEarnt: Yup.number().typeError("Fee Earnt must be a number").min(0, "Fee Earnt must be positive"),
        internalCost: Yup.number().typeError("Internal Cost must be a number").min(0, "Internal Cost must be positive"),
        subcontractorPaid: Yup.number().typeError("Subcontractor Cost must be a number").min(0, "Subcontractor Cost must be positive"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            onSubmit(values);
            onClose();
            console.log(values);
        },
    });

    const { touched, errors, handleSubmit, values, setFieldValue } = formik;

    useEffect(() => {
        const { costRanking, timeRanking, qualityRanking } = values;
        const hasInvalidRanking = [costRanking, timeRanking, qualityRanking].some(
            ranking => ranking?.charAt(0) === 'C' || ranking?.charAt(0) === 'D'
        );
        setFieldValue('wasSuccessful', hasInvalidRanking ? 'No' : 'Yes');
    }, [values.costRanking, values.timeRanking, values.qualityRanking, setFieldValue]);

    const renderField = (label, fieldName, type = "text", isDropdown = false, options = [], disabled = false, multiline = false) => (
        <Grid container sx={{ width: "100%" }}>
            <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>{label}: </p>
            </Grid>
            <Grid item xs={9} sx={{ mt: 1 }}>
                {isDropdown ? (
                    <Dropdown
                        options={options}
                        initialSelectedValue={formik.values[fieldName]}
                        placeholder={`Select ${label}`}
                        onValueChange={(newValue) => formik.setFieldValue(fieldName, newValue)}
                        PopUp={true}
                        errorState={Boolean(touched[fieldName] && errors[fieldName])}
                    />
                ) : (
                    <TextField
                        fullWidth
                        value={formik.values[fieldName]}
                        type={type}
                        onChange={(e) => formik.setFieldValue(fieldName, e?.target?.value)}
                        inputProps={{
                            style: {
                                color: formik.values[fieldName] ? "black" : "inherit",
                                cursor: disabled ? "not-allowed" : "text",
                            },
                            disabled: disabled,
                        }}
                        placeholder={label}
                        sx={textfieldStyle}
                        error={Boolean(touched[fieldName] && errors[fieldName])}
                        helperText={touched[fieldName] && errors[fieldName]}
                        multiline={multiline}
                        rows={multiline ? 1.5 : 1}
                    />
                )}
            </Grid>
        </Grid>
    );

    return (
        <div className={styles.backGround} style={{ position: "fixed", alignItems: "flex-start" }}>
            <div className={styles.form} style={{ width: "500px" }} onClick={handleFormClick}>
                <div className={styles.momentTitle}>MOMENT</div>
                <div className={styles.sectionTitle}>Update Task Details</div>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate style={{ width: "100%" }}>
                        {renderField("Runner", "runner", "text", false, [], true)}
                        {renderField("Task Outsourced", "taskOutsourced", "text", false, [], true)}
                        {renderField("Project Address", "projectAddress", "text", false, [], true)}
                        {renderField("Status", "status", "text", true, statusOptions)}
                        {renderField("Total Fee Earnt", "feeEarnt", "number")}
                        {renderField("Internal Cost", "internalCost", "number")}
                        {renderField("Subcontractor Paid", "subcontractorPaid", "number")}
                        {renderField("Cost Ranking", "costRanking", "text", true, costRankingOptions)}
                        {renderField("Time Ranking", "timeRanking", "text", true, timeRankingOptions)}
                        {renderField("Quality Ranking", "qualityRanking", "text", true, qualityRankingOptions)}
                        {renderField("Was this Project Successfully Outsourced?", "wasSuccessful", "text", false, [], true)}
                        {renderField("Final Comment", "finalComment", "text", false, [], false, true)}
                    </Form>
                </FormikProvider>

                <div className={styles.updateAndCancel}>
                    <div className={styles.updateButton} onClick={handleSubmit}>
                        <div className={buttonStyles.YesButtonComponentStyling}>UPDATE</div>
                    </div>
                    <div className={styles.cancelButton} onClick={onClose}>
                        <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
                    </div>
                </div>

                <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
            </div>
        </div>
    );
};

export default TaskDetailsForm;
