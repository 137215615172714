import React from "react";
import { useMsal } from "@azure/msal-react";
import { useFormik } from "formik";
import { FormikProvider } from "formik";
import textfieldStyle from "../../GlobalStyles/styles";
import { TextField } from "@mui/material";
import buttonStyles from "../../Components/YesNoButtonsCSS/YesNoButtons.module.css";
import styles from "../../Components/FeePropFeePopUp/FeePropFeePopUp.module.css";
import { useDispatch } from "../../redux/store";
import { updateInvoicePaid } from "../../redux/slices/raiseInvoice";

const InvoicePaidPopUp = ({
  isVisible,
  onClose,
  jobNumber,
  invoiceNumber,
  phaseTableRows,
  logValues,
  activity,
  status,
}) => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const dispatch = useDispatch();
  console.log(logValues,'logValues');

  const initialValues = {
    payment: phaseTableRows
      .filter((data) => data.isSelected)
      .reduce((acc, cur) => {
        return parseFloat(acc) + parseFloat(cur.invoiceAmount
          ? cur.invoiceAmount * (cur.vat / 100) + parseFloat(cur.invoiceAmount)
          : 0);
      }, 0),
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const modalData = {
        jobNumber: jobNumber,
        invoiceNumber: invoiceNumber,
        amountPaid: values.payment,
        updatedBy: account.username,
      };

      const logBookbody = {
        invoiceID: invoiceNumber ? invoiceNumber : "",
        status: status?.toLowerCase(),
        activity: activity,
        comment: logValues.comment,
        email: account.username,
      };
      dispatch(updateInvoicePaid(modalData, logBookbody));
      onClose(); // Close the modal
    },
  });
  const { touched, errors, handleSubmit } = formik;

  if (!isVisible) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <FormikProvider value={formik} handleSubmit={handleSubmit}>
          <p className={styles.text}>
            Please confirm the payment for this project
          </p>
          <div className={styles.commentModal_feeSection}>
            <TextField
              value={formik.values.payment}
              type="number"
              onChange={(e) =>
                formik.setFieldValue("payment", e?.target?.value)
              }
              placeholder="£"
              sx={textfieldStyle}
              error={Boolean(touched.payment && errors.payment)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <div style={{ width: "74px", height: "25px" }}>
              <button
                className={buttonStyles.YesButtonComponentStyling}
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </div>
            <div style={{ width: "74px", height: "25px", marginLeft: "10px" }}>
              <button
                className={buttonStyles.NoButtonComponentStyling}
                onClick={onClose}
              >
                CANCEL
              </button>
            </div>
          </div>
        </FormikProvider>
      </div>
    </div>
  );
};

export default InvoicePaidPopUp;
