import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';


// Redux

// ----------------------------------------------------------------------

let initialState = {
    rows: [],
    totalRows: 0,
    runnerOptions: [],
    phaseList: [],
    AWFLoading: false,
    SubConFormLoading: false,
    loading: true,
    showSuccess: false,
    error: null,
};

export const slice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setProjectListData(state, action) {
            state.rows = action.payload;
        },

        setProjectListRowCount(state, action) {
            state.totalRows = action.payload;
        },

        setProjectListLoading(state, action) {
            state.loading = action.payload;
        },

        setSubConFormLoading(state, action) {
            state.SubConFormLoading = action.payload;
        },

        setAWFLoading(state, action) {
            state.AWFLoading = action.payload;
        },

        setTeamOptions(state, action) {
            state.teamOptions = action.payload;
        },

        setPhasesList(state, action) {
            state.phaseList = action.payload;
        },

        setShowSuccess(state, action) {
            state.showSuccess = action.payload;
        },

        hasError(state, action) {
            state.error = action.payload;
        },

    },
});

export const {
    setProjectListData,
    setProjectListRowCount,
    setProjectListLoading,
    setTeamOptions,
    setShowSuccess,
    hasError,
} = slice.actions;

export function fetchWIPExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the project data in Excel format
            const response = await axios.get(`${API_URL}/api/export/WIP`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'WIP_Export.xlsx'); // Setting the default file name for Excel
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

export function fetchPhasesExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the project data in CSV format
            const response = await axios.get(`${API_URL}/api/export/phases`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Phases Export.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

export function fetchFullProjectExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the project data in CSV format
            const response = await axios.get(`${API_URL}/api/export/projects/full`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Project Export.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

export function exportTimesheets() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the timesheet data in CSV format
            const response = await axios.get(`${API_URL}/api/export/timesheets`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Timesheet Dashboard.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'TIMESHEET_DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'TIMESHEET_DOWNLOAD_ERROR', error });
        }
    };
}

export function getProjectList(currentPage, rowsPerPage, searchTerm, team, statusFilter, sortColumn, sortDirection, runnerFilter, tagFilter) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setProjectListLoading(true));
        try {
            const response = await axios.get(
                `${API_URL}/api/projects/projectList?page=${currentPage}&rowsPerPage=${rowsPerPage}&searchTerm=${searchTerm}&teamFilter=${team === "All" ? "" : team}&statusFilter=${statusFilter}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&runnerFilter=${runnerFilter === "All" ? "" : runnerFilter}&tagFilter=${tagFilter}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            let response_data = response.data

            console.log(response_data)

            dispatch(slice.actions.setProjectListData(response_data.rows));
            dispatch(slice.actions.setProjectListRowCount(response_data.totalRows));
            dispatch(slice.actions.setProjectListLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setProjectListLoading(false));
        }
    };
}

export function deleteBillingPlan(id, fetchpInvoiceList) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            await axios.delete(`${API_URL}/api/invoice/projects/invoicing/billing/${id}`);
            fetchpInvoiceList()
        } catch (error) {
            console.error('Error deleting project row:', error);
            dispatch(hasError(error));
        }
    };
};

export function postUpdateClient(formData) {
    const API_URL = process.env.REACT_APP_API_URL;

    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/projects/projectProfile/UpdateClient`,
                formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );

            return response;
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error.response.data.message));
        }
    };
}

export function postAWF(formData) {
    const API_URL = process.env.REACT_APP_API_URL;

    return async () => {
        try {
            dispatch(slice.actions.setAWFLoading(true));
            const response = await axios.post(`${API_URL}/api/projects/projectProfile/AWF`,
                formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );
            dispatch(slice.actions.setAWFLoading(false));

            return response;
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error.response.data.message));
        }
    };
}

export function postSubConForm(formData) {
    const API_URL = process.env.REACT_APP_API_URL;

    return async () => {
        try {
            dispatch(slice.actions.setSubConFormLoading(true));
            const response = await axios.post(`${API_URL}/api/projects/projectProfile/addSubcontractor`,
                formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );
            dispatch(slice.actions.setSubConFormLoading(false));

            return response;
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error.response.data.message));
        }
    };
}

export function getProjectPhases(projectNumber) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.get(
                `${API_URL}/api/projects/validPhases?projectNumber=${projectNumber}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            let response_data = response.data.data

            dispatch(slice.actions.setPhasesList(response_data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postResubmitFBP(projectNumber) {
    const API_URL = process.env.REACT_APP_API_URL;

    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/Admin/Project/ResubmitFBP`,
                projectNumber, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );
            dispatch(slice.actions.setShowSuccess(true));


            return response;
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.setShowSuccess(false));
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postSubcontractorTimesheets(formData) {
    const API_URL = process.env.REACT_APP_API_URL;

    return async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/api/suppliers/subcontractor/timesheet`, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            dispatch(slice.actions.setShowSuccess(true));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.setShowSuccess(false));
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function fetchTeamOptions() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            const result = await axios.get(`${API_URL}/api/teamOptions`);
            dispatch(slice.actions.setTeamOptions(result.data));
        } catch (error) {
            console.error('Error fetching teams:', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export default slice.reducer;