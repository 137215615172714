import React, { useState } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import DropDownV2 from "../DropDownBox/DropdownV2";
import Dropdown from '../DropDownBox/Dropdown';
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";

import { getSubcontractor } from "../../redux/slices/suppliers";

const SubcontractorForm = ({ projectNumber, Address, Runner, onSubmit, onClose }) => {
  const [taskOptions, setTaskOptions] = useState([
    "Site Visit",
    "Schemes",
    "Calculations",
    "Drawings",
    "RC Detailing",
    "Reports",
  ]);

  const dispatch = useDispatch();
  const { subcontractor } = useSelector(
    (state) => state.suppliers
  );

  const modifiedSubcontractor = [...taskOptions];

  const handleSearchChange = (newSearchTerm, type) => {
    dispatch(getSubcontractor(newSearchTerm));
  };

  const handleAddCustomTask = (newValue) => {
    console.log(newValue)

    if (newValue && !taskOptions.includes(newValue)) {
      setTaskOptions((prevOptions) => [...prevOptions, newValue]);
    }
  };

  const handleFormClick = (e) => {
    e.stopPropagation(); // Stops the click event from bubbling up to the parent div
  };

  const initialValues = {
    projectNumber: projectNumber,
    subcontractorName: "",
    subcontractorID: "",
    projectAddress: Address,
    runner: Runner,
    taskOutsourced: [],
  };

  const validationSchema = Yup.object().shape({
    subcontractorName: Yup.string().required("Subcontractor Name is required"),
    projectAddress: Yup.string().required("Project Address is required"),
    runner: Yup.string().required("Runner is required"),
    taskOutsourced: Yup.array()
      .min(1, "At least one task must be selected")
      .required("Task Outsourced is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmit(values);
      onClose();
      console.log(values);
    },
  });
  const { getFieldProps, touched, errors, handleSubmit } = formik;

  return (
    <div
      className={styles.backGround}
      style={{ position: "fixed", alignItems: "flex-start" }}
    >
      <div
        className={styles.form}
        style={{ width: "500px" }}
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Add a Subcontractor</div>

        <FormikProvider value={formik} handleSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Subcontractor Name: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <DropDownV2
                  options={subcontractor}
                  initialSelectedValue={formik.values.subcontractorName}
                  placeholder={"Select Subcontractor"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue(
                      "subcontractorName",
                      newValue.label
                    );
                    formik.setFieldValue("subcontractorID", newValue.value);
                  }}
                  onSearchChange={handleSearchChange}
                  PopUp={true}
                  searchable={true}
                  addNew={true}
                  errorState={Boolean(
                    touched.subcontractorName && errors.subcontractorName
                  )}
                  clientType="subcontractor"
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Task Outsourced:{" "}
                </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1.5 }}>
                <Dropdown
                  options={modifiedSubcontractor}
                  initialSelectedValue={formik.values.taskOutsourced}
                  placeholder={"Select Tasks"}
                  onValueChange={(newValue) =>
                    formik.setFieldValue("taskOutsourced", newValue)
                  }
                  mode='multi'
                  allowOtherOption={true}
                  onAddNew={handleAddCustomTask}
                  PopUp={true}
                  errorState={Boolean(
                    touched.taskOutsourced && errors.taskOutsourced
                  )}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>

        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={handleSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>SUBMIT</div>
          </div>
          <div className={styles.cancelButton} onClick={onClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
      </div>
    </div>
  );
};

export default SubcontractorForm;
