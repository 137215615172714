import React, { useState } from 'react';
import styles from "../../Components/Table/TableV2.module.css";
import EditIcon from "../../assets/Edit.svg";
import AddIcon from "../../assets/Add.svg";
import DeleteIcon from "../../assets/Delete.svg";
import TaskDetailsForm from '../../Components/TaskDetailsForm/TaskDetailsForm';
import TimesheetSubconForm from '../../Components/TimesheetSubconForm/TimesheetSubconForm';

import { dispatch } from '../../redux/store';
import { updateProjectDetails, deleteSubcontractorTask } from '../../redux/slices/suppliers';
import { postSubcontractorTimesheets } from '../../redux/slices/project';
import ConfirmationPopUp from '../../Components/ConfirmationPopUp/ConfirmationPopUp';

const SubcontractorTaskTable = ({
    subcontractorId,
    subcontractorName,
    rowData,
    isAdmin = false,
}) => {
    const rowClasses = `${styles.tableRow}`;
    const [displayTaskForm, setDisplayTaskForm] = useState(false);
    const [displayTimesheetForm, setDisplayTimesheetForm] = useState(false);
    const [displayConfirmationPopUp, setDisplayConfirmationPopUp] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const openTaskForm = (display, rowData) => {
        setSelectedRow(rowData);
        setDisplayTaskForm(display);
    }

    const openTimesheetForm = (display, rowData) => {
        setSelectedRow(rowData);
        setDisplayTimesheetForm(display);
    }

    const openConfirmationPopUp = (display, rowData) => {
        setSelectedRow(rowData);
        setDisplayConfirmationPopUp(display);
    }

    const handleDelete = (taskID) => {
        dispatch(deleteSubcontractorTask(taskID, subcontractorId));
        setDisplayConfirmationPopUp(false);
    };

    const handleTaskFormSubmit = async (formData, subcontractorId) => {
        console.log(formData);
        dispatch(updateProjectDetails(formData, subcontractorId));
    }

    const handleTimesheetFormSubmit = async (formData) => {
        console.log(formData);
        dispatch(postSubcontractorTimesheets(formData));
    }

    return (
        <React.Fragment>
            <div
                className={styles.tableContainer}
                style={{

                    overflowY: "auto",
                    overflowX: "auto", // Allow horizontal scrolling
                    background: "inherit",
                    marginBottom: "20px"
                }}
            >
                <table className={styles.table} style={{ width: 'max-content' }}>
                    <thead>
                        <tr className={styles.tableHeader}>
                            {isAdmin && (
                                <React.Fragment>
                                    <th className={styles.th} style={{ width: "3%", textAlign: "center" }}>
                                        <div className={styles.CenterContent}>Delete</div>
                                    </th>
                                    <th className={styles.th} style={{ width: "3%", textAlign: "center" }}>
                                        <div className={styles.CenterContent}>
                                            Edit
                                        </div>
                                    </th>
                                    <th className={styles.th} style={{ width: "3%", textAlign: "center" }}>
                                        <div className={styles.CenterContent}>
                                            Timesheets
                                        </div>
                                    </th>
                                </React.Fragment>
                            )}
                            <th key={0} className={styles.th}>
                                Project Address
                            </th>
                            {[
                                "Runner",
                                "Task Outsourced",
                                "Status",
                                "Subcontractor Paid",
                                "Fee Earnt",
                                "Internal Cost",
                                "Cost Ranking",
                                "Time Ranking",
                                "Quality Ranking",
                                "Was this Project successfully outsourced?",
                                "Final Comment"
                            ].map((header, index) => (
                                <th
                                    key={index + 1}
                                    className={styles.th}
                                    // Conditional alignment here 👇
                                    style={{ textAlign: header === "Final Comment" ? "left" : "center" }}
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {rowData?.length === 0 ? (
                            <tr>
                                <td
                                    colSpan={9}
                                    style={{
                                        textAlign: "center",
                                        color: "#4a60a8",
                                        fontWeight: "bold",
                                    }}
                                >
                                    No data available.
                                </td>
                            </tr>
                        ) : (
                            rowData?.map((row, rowIndex) => {
                                const values = [
                                    row.runner,
                                    row.taskOutsourced,
                                    row.status,
                                    row.subcontractorPaid,
                                    row.totalFeeEarnt,
                                    row.totalInternalCost,
                                    row.costRanking ? row.costRanking.charAt(0) : "TBA",
                                    row.timeRanking ? row.timeRanking.charAt(0) : "TBA",
                                    row.qualityRanking ? row.qualityRanking.charAt(0) : "TBA",
                                    row.wasProjectSuccessfullyOutsourced ? "Yes" : "No",
                                    row.finalComment,
                                ];

                                const statusIndex = 2;

                                return (
                                    <tr key={rowIndex} className={rowClasses} style={{ background: "white" }}>
                                        {isAdmin && (
                                            <React.Fragment>
                                                <td className={styles.td} style={{ textAlign: "center" }}>
                                                    <div className={styles.CenterContent}>
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openConfirmationPopUp(true, row)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className={styles.td} style={{ textAlign: "center" }}>
                                                    <div className={styles.CenterContent}>
                                                        <img
                                                            src={EditIcon}
                                                            alt="Edit"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openTaskForm(true, row)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className={styles.td} style={{ textAlign: "center" }}>
                                                    <div className={styles.CenterContent}>
                                                        <img
                                                            src={AddIcon}
                                                            alt="Add"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openTimesheetForm(true, row)}
                                                        />
                                                    </div>
                                                </td>
                                            </React.Fragment>
                                        )}
                                        <td key={0} className={styles.td}>
                                            {row.projectAddress}
                                        </td>
                                        {values.map((cell, index) => (
                                            <td
                                                key={index + 1}
                                                className={styles.td}
                                                style={{
                                                    textAlign: index === 10 ? "left" : "center", // Index 10 = Final Comment
                                                }}
                                            >
                                                {index >= statusIndex && (cell === "" || cell === null) ? "TBA" : cell}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>

            {displayTimesheetForm &&
                <TimesheetSubconForm
                    projectNumber={selectedRow?.projectNumber}
                    subcontractorName={subcontractorName}
                    projectAddress={selectedRow?.projectAddress}
                    onSubmit={(values) => handleTimesheetFormSubmit(values)}
                    onClose={() => setDisplayTimesheetForm(false)}
                />
            }

            {displayConfirmationPopUp &&
                <ConfirmationPopUp
                    isVisible={displayConfirmationPopUp}
                    onConfirm={() => handleDelete(selectedRow?.ID)}
                    onClose={() => setDisplayConfirmationPopUp(false)}
                />
            }

            {displayTaskForm &&
                <TaskDetailsForm
                    projectData={selectedRow}
                    onSubmit={(values) => handleTaskFormSubmit(values, subcontractorId)}
                    onClose={() => setDisplayTaskForm(false)}
                />
            }
        </React.Fragment>
    );
};

export default SubcontractorTaskTable;
