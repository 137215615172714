import React, { useEffect, useState, useRef } from 'react';
import styles from './NumberScroller.module.css';

function NumberScroller({ initialData = 0, onValueChange, liningColour='', locked = false, type='', color='black'}) {
  // Helper function to format the number in the shortest form or return empty string
  const formatNumber = (num) => {
    if (num === '') return ''; // Return empty string if num is empty

    // Convert to a fixed string first to avoid floating-point issues
    let fixedNum = num.toFixed(2);
    // Remove trailing zeroes and a possible trailing decimal point
    return fixedNum.replace(/\.00$|(\.\d*?[1-9])0+$/, '$1');
  };

  const [value, setValue] = useState(initialData === 0 ? '' : formatNumber(initialData));
  const [isInvalid, setIsInvalid] = useState(false);
  const numberRef = useRef(null);
  const max = 100;
  const min = -100;

  // Update value when initialData changes
  useEffect(() => {
    setValue(initialData === 0 ? '' : formatNumber(initialData));
  }, [initialData]);

  const handleNumberChange = (e) => {
    const numStr = e.target.value;
  
    // If the input is cleared, set the value to 0
    if (numStr === '') {
      setValue('0');
      if (onValueChange) {
        onValueChange(0);
      }
      return;
    }
  
    // Parse the input as a float and round to two decimal places if necessary
    let num = Math.round(parseFloat(numStr) * 100) / 100;
  
    // If num is not a number, default to 0
    if (isNaN(num)) {
      num = 0;
    } else {
      // Ensure num is within the specified range
      num = Math.min(Math.max(num, min), max);
    }
  
    // Format the number to remove trailing zeroes if any
    const formattedValue = formatNumber(num);
  
    setValue(formattedValue);
  
    if (onValueChange) {
      onValueChange(num);
    }
  };

  const disableScroll = (e) => {
    e.target.blur();
  };
  
  useEffect(() => {
    if (isInvalid) {
      const timerId = setTimeout(() => {
        setIsInvalid(false);
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [isInvalid]);

  const inputClass = `${styles.numberInput} ${isInvalid ? styles.invalidInput : ''} ${type == "Weekend" ? styles.Weekend : ''}`;
  
  if (locked) {
    return <span style={{paddingInline:'18px'}}>{formatNumber(initialData)}</span>;
  }

  return (
    <input 
      ref={numberRef}
      type="number" 
      className={inputClass} 
      value={value} 
      onChange={handleNumberChange}
      onWheel={disableScroll} // Disable scrolling
      style={{
        borderColor: liningColour,
        color: color // Use the color prop to dynamically set the text color
      }}
      max={max}
      min={min}
    />
  );
}

export default NumberScroller;

