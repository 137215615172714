import React, { useEffect, useState, useRef } from 'react';
import styles from './EmployeeInfo.module.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../../authConfig';
import ClickableTitle from '../../../Components/ClickableTitle/ClickableTitle.js';
import EditDiv from '../../../Components/EditDiv/EditDiv.js';
import DownloadIcon from '../../../assets/Download.svg';
import EditIcon from '../../../assets/Edit.svg';
import MoreOptions from '../../../assets/MoreOptions.svg';
import List from '../../../Components/List/List.js'
import MoreOptionsButton from '../../../Components/MoreOptions/MoreOptions.js'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Table from '../../../Components/Table/Table'
import BookedLeaveTable from '../Holiday/BookedLeaveTable';
import '../Holiday/Holiday.css';
import { da } from 'date-fns/locale';

function EmployeeInfo({ editable }) {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const containerRef = useRef(null);
  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const [image, setImage] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const [displayEditDiv, setDisplayEditDiv] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [displayMoreOptions, setDisplayMoreOptions] = useState(false);
  const [editType, setEditType] = useState('Update');
  const [rowIndex, setRowIndex] = useState(null)

  // useStates used to hold all employee info
  const [overviewPerson, setoverviewPerson] = useState(null);
  const [overviewManaging, setoverviewManaging] = useState(null);
  const [EmploymentJobTitle, setEmploymentJobTitle] = useState(null);
  const [EmploymentFullTime, setEmploymentFullTime] = useState(null);
  const [EmploymentHRaccess, setEmploymentHRaccess] = useState(null);
  const [Equipment, setEquipment] = useState(null);
  const [PersonalBiologicalInfo, setPersonalBiologicalInfo] = useState(null);
  const [PersonalContactInfo, setPersonalContactInfo] = useState(null);
  const [EmploymentSensitiveInfo, setEmploymentSensitiveInfo] = useState(null);
  const [MedicalFacts, setMedicalFacts] = useState(null);
  const [EmergencyContacts, setEmergencyContacts] = useState(null);
  const [Documents, setDocuments] = useState(null);

  const [serviceDays, setServiceDays] = useState([]);
  const [carryDays, setCarryDays] = useState([]);
  const [contractAllowance, setContractAllowance] = useState([]);
  const [holidayPurchased, setHolidayPurchased] = useState([]);
  const [holidayAdjustment, setHolidayAdjustment] = useState([]);
  const [EditAllowances, setEditAllowances] = useState([]);
  const [totalHolidayAllowance, setTotalHolidayAllowance] = useState([]);
  const [holidayDays, setHolidayDays] = useState([]);
  const [totalStudyAllowance, setTotalStudyAllowance] = useState([]);
  const [studyDays, setStudyDays] = useState([]);
  const [rows, setRows] = useState([]);

  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => setHash(window.location.hash);
    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);


  const [reload, setReload] = useState(0);

  useEffect(() => {
    setIsDataFetched(false);

    const hash = window.location.hash;
    let currentEmployeeID = 0; // use a local variable

    if (hash) {
      const parsed = parseInt(hash.substring(1));

      // make sure parsed is a number
      if (!isNaN(parsed)) {
        currentEmployeeID = parsed; // update the local variable
      }
    }

    console.log('EmployeeID: ', currentEmployeeID)

    const fetchEmployeeInfo = fetch(`${API_URL}/api/Profile/EmployeeInfo/information?userEmail=${encodeURIComponent(account.username)}&EmployeeID=${encodeURIComponent(currentEmployeeID)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log(`${API_URL}/api/Profile/EmployeeInfo/ProfilePicture?userEmail=${encodeURIComponent(account.username)}&EmployeeID=${encodeURIComponent(currentEmployeeID)}`)
    const fetchEmployeeProfilePicture = fetch(`${API_URL}/api/Profile/EmployeeInfo/ProfilePicture?userEmail=${encodeURIComponent(account.username)}&EmployeeID=${encodeURIComponent(currentEmployeeID)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const fetchHolidaysList = fetch(`${API_URL}/api/ProfileHoliday/userHolidaysList?userEmail=${encodeURIComponent(account.username)}&EmployeeID=${encodeURIComponent(currentEmployeeID)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const fetchAllowance = fetch(`${API_URL}/api/ProfileHoliday/userAllowance?userEmail=${encodeURIComponent(account.username)}&EmployeeID=${encodeURIComponent(currentEmployeeID)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    Promise.all([fetchEmployeeInfo, fetchEmployeeProfilePicture, fetchHolidaysList, fetchAllowance])
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(([fetchEmployeeInfo, fetchEmployeeProfilePicture, fetchedHolidaysList, fetchedAllowance]) => {
        setRows(fetchedHolidaysList);
        setCarryDays(fetchedAllowance.carryDays);
        setHolidayPurchased(fetchedAllowance.holidayPurchased);
        setHolidayAdjustment(fetchedAllowance.holidayAdjustment);
        setContractAllowance(fetchedAllowance.contractAllowance);
        setServiceDays(fetchedAllowance.serviceDays);
        setHolidayDays(fetchedAllowance.holidayAllowance);
        setStudyDays(fetchedAllowance.studyAllowance);
        setTotalHolidayAllowance(fetchedAllowance.totalHolidayAllowance);
        setTotalStudyAllowance(fetchedAllowance.totalStudyAllowance);
        setoverviewPerson(fetchEmployeeInfo.overviewPerson);
        setEditAllowances(fetchedAllowance.holidayOverView);
        setoverviewManaging(fetchEmployeeInfo.overviewManaging);
        setEmploymentJobTitle(fetchEmployeeInfo.EmploymentJobTitle);
        setEmploymentFullTime(fetchEmployeeInfo.EmploymentFullTime);
        setEmploymentHRaccess(fetchEmployeeInfo.EmploymentHRaccess);
        setEquipment(fetchEmployeeInfo.Equipment);
        setPersonalBiologicalInfo(fetchEmployeeInfo.PersonalBiologicalInfo);
        setPersonalContactInfo(fetchEmployeeInfo.PersonalContactInfo);
        setEmploymentSensitiveInfo(fetchEmployeeInfo.EmploymentSensitiveInfo)
        setMedicalFacts(fetchEmployeeInfo.MedicalFacts);
        setEmergencyContacts(fetchEmployeeInfo.EmergencyContacts);
        console.log(fetchEmployeeInfo.EmergencyContacts)
        setDocuments(fetchEmployeeInfo.Documents);
        setImage(`data:image/jpeg;base64,${fetchEmployeeProfilePicture.base64_image}`);
        setIsDataFetched(true);
      });
    setDisplayEditDiv(false);
  }, [reload, hash, instance, account]);

  const handleMoreOptions = () => {
    setDisplayMoreOptions(prevDisplayMoreOptions => !prevDisplayMoreOptions);
  };

  const handleCancelationReload = async (currentId) => {
    setIsDataFetched(false);
    try {
      const response = await fetch(`${API_URL}/api/ProfileHoliday/CancelingLeaveRequest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: account.username, leaveID: currentId }),
      });
      if (response.ok) {
        const data = await response.json();
      } else {
      }
      setReload(reload + 1);
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    } finally {
      setIsDataFetched(true);
    }
  };

  // Outside click detector
  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplayMoreOptions(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const [deleteData, setDeleteData] = useState('')

  const handleRowDelete = (data, SQLid) => () => {
    setEditType('Delete')
    setRowIndex(SQLid)
    setDeleteData(data)
  };

  useEffect(() => {
    if (editType === 'Delete' && rowIndex != null) {
      handleUpdateClick(deleteData.DataEditTitle, null);
    }
  }, [deleteData]);

  const handleRowEdit = (data, SQLid, index) => () => {
    console.log('SQLid: ', index)
    // Clone the data object to avoid mutating original data
    let newData = { ...data };
    // Transform the DataEdit array
    newData.DataEdit = newData.DataEdit.map((item, i) => {
      return {
        ...item,
        dataName: newData.headers[i + 1], // Add 1 to i to skip the first item
        data: newData.rows[index][i + 1] // Same here
      };
    });
    setEditType('Update')
    setRowIndex(SQLid)
    handleEdit(newData)();
  };

  const handleAddEdit = (data) => () => {
    // Clone the data object to avoid mutating original data
    let newData = { ...data };

    // Transform the DataEdit array
    newData.DataEdit = newData.DataEdit.map((item, i) => {
      return {
        ...item,
        dataName: newData.headers[i + 1], // Add 1 to i to skip the first item
        data: '' // Empty string
      };
    });
    setCurrentData(newData);
    setEditType('Add')
    setDisplayEditDiv(true);
  };

  const handleEdit = (data) => () => {
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  // Then when the UPDATE button is clicked, you send selectedValues to the backend
  const handleUpdateClick = async (DataTitle, selectedValues) => {
    setIsDataFetched(false);
    console.log('DataTitle, selectedValues', DataTitle, selectedValues)
    try {
      const hash = window.location.hash;
      let currentEmployeeID = 0; // use a local variable

      if (hash) {
        const parsed = parseInt(hash.substring(1));

        // make sure parsed is a number
        if (!isNaN(parsed)) {
          currentEmployeeID = parsed; // update the local variable
        }
      }

      const response = await fetch(`${API_URL}/api/Profile/EmployeeInfo/UpdatingInformation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          EmployeeID: currentEmployeeID,
          UserEmail: account.username,
          Action: editType,
          SectionName: DataTitle,
          UpdatedValues: selectedValues,
          RowID: rowIndex,
        }),
      })
      if (response.ok) {
        setReload(reload + 1);
      }
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    }
  };

  return (
    <>
      {isDataFetched ? (
        <div className={styles.MainContent}>

          <div className={styles.title} style={{ marginLeft: '18.6px' }}>Overview</div>
          <div className={styles.infoSector}>
            <div className={styles.imageSection}>
              {image ? <img src={image} className={styles.profilePic} alt="Profle Picture" /> : null}
              {window.location.hash ? (
                <div className={styles.offBoardButton}>OFF BOARD</div>
              ) : null}
            </div>
            <List data={overviewPerson} editable={editable} handleEdit={handleEdit} message={'No data'} />
            <List data={overviewManaging} editable={editable} handleEdit={handleEdit} message={'No data'} />
          </div>

          <div className={styles.notSoMainContent}>
            <ClickableTitle titleName='Employment Info'
              section={
                <div className={styles.infoSector}>
                  <List data={EmploymentJobTitle} editable={editable} handleEdit={handleEdit} message={'No data'} />
                  <List data={EmploymentFullTime} editable={editable} handleEdit={handleEdit} message={'No data'} />
                  <List data={EmploymentHRaccess} editable={editable} handleEdit={handleEdit} message={'No data'} />
                </div>
              }
            />

            <ClickableTitle titleName='Equipment'
              section={
                <div>
                  <Table data={Equipment.Update} editable={editable} handleRowEdit={handleRowEdit} handleRowDelete={handleRowDelete} />
                  <div className={styles.addButtonContainer}>
                    {
                      editable ? <div className={styles.addButton} onClick={handleAddEdit(Equipment.Add)}>ADD</div> : <div></div>
                    }
                  </div>
                </div>
              }
            />

            <ClickableTitle titleName='Personal Info'
              section={
                <div className={styles.infoSector}>
                  <List data={PersonalBiologicalInfo} editable={true} handleEdit={handleEdit} message={'No data'} />
                  <List data={PersonalContactInfo} editable={true} handleEdit={handleEdit} message={'No data'} />
                  <List data={EmploymentSensitiveInfo} editable={true} handleEdit={handleEdit} message={'No data'} />
                </div>
              }
            />

            <ClickableTitle titleName='Medical Facts'
              section={
                <div>
                  <Table data={MedicalFacts.Update} editable={true} handleRowEdit={handleRowEdit} handleRowDelete={handleRowDelete} />
                  <div className={styles.addButtonContainer}>
                    <div className={styles.addButton} onClick={handleAddEdit(MedicalFacts.Add)}>ADD</div>
                  </div>
                </div>
              }
            />

            <ClickableTitle titleName='Emergency Contacts'
              section={
                <div>
                  <Table data={EmergencyContacts.Update} editable={true} handleRowEdit={handleRowEdit} handleRowDelete={handleRowDelete} />
                  <div className={styles.addButtonContainer}>
                    <div className={styles.addButton} onClick={handleAddEdit(EmergencyContacts.Add)}>ADD</div>
                  </div>
                </div>
              }
            />

            <ClickableTitle titleName='Documents'
              section={
                <div>
                  <Table data={Documents.Update} editable={editable} handleRowEdit={handleRowEdit} handleRowDelete={handleRowDelete} />
                  <div className={styles.addButtonContainer}>
                    {
                      editable ? <div className={styles.addButton} onClick={handleAddEdit(Documents.Add)}>ADD</div> : <div></div>
                    }
                  </div>
                </div>
              }
            />

            {editable && <ClickableTitle titleName='Leave'
              section={
                <div className='bookedLeaveInfo'>

                  {/* Top Row */}
                  <div className='daysLeft' style={{ marginTop: '28px' }}>
                    <div style={{ marginRight: '6px' }} className='BookedAndAllowanceDays'>
                      <div style={{ marginBottom: '8px' }} className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalHolidayAllowance}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Allowance</span>
                        </div>
                      </div>
                      <div className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalHolidayAllowance - holidayDays}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Booked</span>
                        </div>
                      </div>
                    </div>
                    <div className='holidayDays'>
                      <div className='holidayDaysNum'>{holidayDays}</div>
                      <div className='holidayDaysText'>
                        <span style={{ display: 'block', marginTop: '4px', fontSize: '14px' }}>Holiday</span>
                        <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Days</span>
                        <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Remaining</span>
                      </div>
                    </div>
                    <div className='studyDays'>
                      <div className='studyDaysNum'>{studyDays}</div>
                      <div className='studyDaysText'>
                        <span style={{ display: 'block', marginTop: '3px', fontSize: '14px' }}>Study</span>
                        <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Days</span>
                        <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Remaining</span>
                      </div>
                    </div>
                    <div style={{ marginLeft: '6px' }} className='BookedAndAllowanceDays'>
                      <div style={{ marginBottom: '8px' }} className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalStudyAllowance}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Allowance</span>
                        </div>
                      </div>
                      <div className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalStudyAllowance - studyDays}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Booked</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bottom Row */}
                  <div className='daysLeft' style={{ marginBottom: '28px' }}>
                    <div style={{ marginRight: '6px' }} className='BookedAndAllowanceDays'>
                      <div className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{contractAllowance}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Contract</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginRight: '6px' }} className='BookedAndAllowanceDays'>
                      <div className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{serviceDays}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Service</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginRight: '6px' }} className='BookedAndAllowanceDays'>
                      <div className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{carryDays}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Carry</span>
                          <span style={{ display: 'block', marginTop: '-5px', fontSize: '14px', width: '62px' }}>Over</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginLeft: '6px' }} className='BookedAndAllowanceDays'>
                      <div className='RequestedDays'>
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{holidayPurchased}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '70px' }}>Purchased</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginLeft: '6px' }} className='BookedAndAllowanceDays'>
                      <div className='RequestedDays' style={{ position: 'relative' }}>
                        {editable && <img className={styles.editIcon} style={{ marginTop: '-3px' }} src={EditIcon} onClick={handleEdit(EditAllowances)} alt="EditIcon" />}
                        <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{holidayAdjustment}</div>
                        <div className='RequestedDaysText'>
                          <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Other</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='bookedLeaveBox' style={{ display: isDataFetched ? 'block' : 'none', marginBottom: '25px' }}>
                    <BookedLeaveTable
                      rows={rows}
                      setRows={setRows}
                      setIsDataFetched={setIsDataFetched}
                      handleCancelationReload={handleCancelationReload}
                      admin={true}
                    />
                  </div>
                </div>
              }
            />}

            {displayEditDiv && <EditDiv data={currentData} handleUpdateClick={handleUpdateClick} setDisplayEditDiv={setDisplayEditDiv} actionType={editType} />}

          </div>
        </div>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center' }}>
          <CircularProgress size={65} />
        </Box>
      )}
    </>
  )
}

export default EmployeeInfo;