import React, { useState, useEffect } from 'react';
import styles from '../Table/TableV2.module.css';

const InvSummaryTable = ({ jsonData, headerMap = {}, clickableRowAdd = false }) => {
    // Default header names mapping
    const defaultHeaderMap = {
        jobNumber: "Job No",
        projectAddress: "Project Address",
        plannedAmount: "Planned Amt.",
        invoicedAmount: "Invoiced Amt.",
    };

    // Merge the provided headerMap with defaultHeaderMap for custom header names
    const resolvedHeaderMap = { ...defaultHeaderMap, ...headerMap };

    // Extract headers in the order defined in defaultHeaderMap
    const headers = Object.keys(defaultHeaderMap).map(key => resolvedHeaderMap[key] || key);

    // Initially convert JSON array to rows format
    const initialRows = jsonData?.data?.map(item =>
        Object.keys(defaultHeaderMap) // Use the order from defaultHeaderMap
            .map(key => item[key]?.toString() || '') // Convert each value to string, or empty string if undefined
    );
    const [rows, setRows] = useState(initialRows);

    // Pagination state and constants
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Set rows per page as desired

    // Calculate the rows to display based on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        const newRows = jsonData?.data?.map(item =>
            Object.keys(defaultHeaderMap) // Ensure the order is maintained
                .map(key => item[key]?.toString() || '') // Convert to string or ''
        );
        setRows(newRows);
    }, [jsonData]); // Only re-run the effect if jsonData changes

    const sortRows = (index) => {
        let key = index;
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        // Adjusted from [...data.rows] to directly use rows derived from jsonData
        const sortedRows = [...rows].sort((a, b) => {
            let valA = a[index];
            let valB = b[index];

            if (valA === null || valB === null) return 0;  // Skip null values

            // If value is a money string, ignore the first character and remove commas
            if (typeof valA === 'string' && valA.charAt(0) === '£') {
                valA = parseFloat(valA.substring(1).replace(/,/g, ''));
                valB = parseFloat(valB.substring(1).replace(/,/g, ''));
            }
            // If the string starts with a number, isolate the number
            else if (typeof valA === 'string' && !isNaN(valA.charAt(0))) {
                const matchA = valA.match(/\d+/);
                const matchB = valB.match(/\d+/);
                if (matchA && matchB) {
                    valA = parseFloat(matchA[0]);
                    valB = parseFloat(matchB[0]);
                }
            }
            // If the string is a date in dd/mm/yy format
            else if (typeof valA === 'string' && /^(\d{2})\/(\d{2})\/(\d{2})$/.test(valA)) {
                const [dayA, monthA, yearA] = valA.split('/').map(Number);
                const [dayB, monthB, yearB] = valB.split('/').map(Number);
                valA = new Date(yearA, monthA - 1, dayA).getTime();
                valB = new Date(yearB, monthB - 1, dayB).getTime();
            }

            if (valA < valB) return direction === 'ascending' ? -1 : 1;
            if (valA > valB) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setSortConfig({ key, direction });
        setRows(sortedRows);
    };

    const handleRowClick = (firstColumnValue) => {
        if (typeof clickableRowAdd === 'string') {
            window.location.href = `${clickableRowAdd}#${firstColumnValue}`;
        }
    };

    const rowClasses = `${styles.tableRow} ${clickableRowAdd ? styles.rowHoverHighlight : ''}`;

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table} style={{ width: '100%', tableLayout: 'fixed' }}>
                <thead>
                    <tr className={styles.tableHeader}>
                        {headers?.map((header, index) => {
                            // Determine the sorting arrow
                            let arrow = '';
                            if (sortConfig.key === index) {
                                arrow = sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
                            }

                            // Set column widths using inline styles
                            const columnWidths = ['15%', '25%', '20%', '20%'];

                            if (index == 0 || index == 2 || index == 3) {
                                return (
                                    <th key={index} className={styles.th} style={{ width: columnWidths[index] }}>
                                        <div className={styles.CenterContent} onClick={() => sortRows(index)}>{header}{arrow}</div>
                                    </th>
                                );
                            }

                            return (
                                <th key={index} className={styles.th} style={{ width: columnWidths[index] }}>
                                    <div onClick={() => sortRows(index)}>{header}{arrow}</div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {rows?.map((row, rowIndex) => (
                        <tr key={rowIndex} className={rowClasses} onClick={() => clickableRowAdd && handleRowClick(row[0])}>
                            {row?.map((cell, cellIndex) => {
                                // Set column widths using inline styles
                                const columnWidths = ['15%', '25%', '20%', '20%'];

                                if (cellIndex == 0) {
                                    return (
                                        <td key={cellIndex} style={{ fontFamily: "'PT Sans', sans-serif", width: columnWidths[cellIndex] }} className={styles.td}>
                                            <div className={styles.CenterContent}>
                                                {cell}
                                            </div>
                                        </td>
                                    );
                                }

                                if (cellIndex == 2 || cellIndex == 3) {
                                    let formattedValue = `£${Number(cell).toLocaleString('en-GB', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}`;
                                    return (
                                        <td key={cellIndex} style={{ fontFamily: "'PT Sans', sans-serif", width: columnWidths[cellIndex] }} className={styles.td}>
                                            <div className={styles.CenterContent}>
                                                {formattedValue}
                                            </div>
                                        </td>
                                    );
                                }

                                return (
                                    <td key={cellIndex} className={styles.td} style={{ width: columnWidths[cellIndex] }}>
                                        <div className="ellipsis-div">{cell}</div>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default InvSummaryTable;