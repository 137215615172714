import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableTh from '../ResizableTh';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from '../../redux/store';
import { setSelectedRow } from '../../redux/slices/rowhighlight';

function SupplierListTable(props) {
  const [columnWidths, setColumnWidths] = React.useState({
    0: 120, // Company
    1: 120, // Main Contact
    2: 50, // Website
    3: 120, // Email
    4: 40, // Phone No.
    5: 150, // Services
    6: 80, // Type
    7: 50, // Preferred
    8: 120, // Comment
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
  };

  const tableRef = React.useRef(); // Create a ref for the table
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const selectedRow = useSelector((state) => state.rowhighlight.selectedRow);

  const setColumnWidth = React.useCallback((index, width) => {
    setColumnWidths((prev) => {
      // Set minimum width constraint
      const minWidth = 50; // Minimum width in pixels
      const adjustedWidth = Math.max(width, minWidth);
      
      const newWidths = { ...prev };
      newWidths[index] = adjustedWidth;
  
      // Calculate total width
      const totalWidth = Object.values(newWidths).reduce((a, b) => a + b, 0);
      const tableWidth = tableRef.current ? tableRef.current.offsetWidth : 0;
  
      // If total width exceeds table width, adjust other columns proportionally
      if (totalWidth > tableWidth) {
        const excessWidth = totalWidth - tableWidth;
        const otherColumns = Object.keys(newWidths).filter(key => key !== index.toString());
        
        // Distribute excess width among other columns
        otherColumns.forEach(colIndex => {
          const currentWidth = newWidths[colIndex];
          const reduction = (currentWidth / (totalWidth - adjustedWidth)) * excessWidth;
          newWidths[colIndex] = Math.max(minWidth, currentWidth - reduction);
        });
      }
  
      return newWidths;
    });
  }, []);

  React.useEffect(() => {
    const scrollPos = localStorage.getItem('scrollPos');
    if (scrollPos && tableRef.current) {
      tableRef.current.scrollTop = parseFloat(scrollPos);
    }
    console.log(props.rows);
  }, []);

  const handleRowClick = (row) => {
    navigate(`SupplierProfile#${row.id}`);
  };

  const uniqueRows = React.useMemo(() => {
    const invNumSet = new Set();
    return props.rows.filter(row => {
      console.log(props.rows)
      if (invNumSet.has(row.id)) {
        return false;
      } else {
        invNumSet.add(row.id);
        return true;
      }
    });
  }, [props.rows]);

  return (
    <div className='ProjectListBox'>
      <div id="suppliersTableContainer" ref={tableRef}>
        <table>
          <thead>
            <tr>
              {['Company', 'Main Contact', 'Website', 'Email', 'Phone No.', 'Services', 'Type', 'Preferred', 'Comment'].map((header, index) => (
                <ResizableTh onResize={(width) => setColumnWidth(index, width)} index={index} key={index}>
                  <span
                    onClick={props.loading ? null : () =>
                      props.handleSort(
                        header.toLowerCase().replace(' ', '').replace('.', '') // Continue using existing logic for other headers
                      )
                    }
                    className={props.loading ? 'disable-click' : ''}
                    >
                    {header}
                    {props.sortColumn === (header.toLowerCase().replace(' ', '').replace('.', '')) && (
                      props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                    )}
                  </span>
                </ResizableTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.loading ? (
              [...Array(50)].map((_, index) => (
                <tr key={index}>
                  {[...Array(9)].map((_, cellIndex) => (
                    <td key={cellIndex} style={{ width: columnWidths[cellIndex] }}>
                      <div className="skeleton" style={{ height: '13px' }}></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              uniqueRows.map((row) => (
                <tr
                  key={row.id}
                  onMouseEnter={() => setHoveredRow(row.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  onClick={() => {
                    handleRowClick(row);
                    dispatch(setSelectedRow(row.id));
                    if (tableRef.current) {
                      localStorage.setItem('scrollPos', tableRef.current.scrollTop);
                    }
                  }}
                  className={`
                  ${hoveredRow === row.id && selectedRow !== row.id ? 'row-hover' : ''}
                  ${selectedRow === row.id ? 'row-selected' : ''}
                `}
                >

                  {/* Company Column */}
                  <td style={{ width: columnWidths[0], maxWidth: '200px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[0]}px`, maxWidth: '200px' }}>{row.company}</div>
                    </Link>
                  </td>

                  {/* Main Contact Column */}
                  <td style={{ width: columnWidths[1], maxWidth: '120px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[1]}px`, maxWidth: '120px' }}>{row.name}</div>
                    </Link>
                  </td>

                  {/* Website Column */}
                  <td style={{ width: columnWidths[2], maxWidth: '120px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[2]}px`, maxWidth: '120px' }}>{row.website}</div>
                    </Link>
                  </td>

                  {/* Email Column */}
                  <td style={{ width: columnWidths[3], maxWidth: '200px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[3]}px`, maxWidth: '200px' }}>{row.email}</div>
                    </Link>
                  </td>

                  {/* Phone Number Column */}
                  <td style={{ width: columnWidths[4], maxWidth: '120px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[4]}px`, maxWidth: '120px' }}>{row.phone}</div>
                    </Link>
                  </td>

                  {/* Services Column */}
                  <td style={{ width: columnWidths[5], maxWidth: '230px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '100px', width: `${columnWidths[5]}px`, maxWidth: '230px' }}>{row.services}</div>
                    </Link>
                  </td>

                  {/* Type Column */}
                  <td style={{ width: columnWidths[6], maxWidth: '100px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[6]}px`, maxWidth: '100px' }}>{row.type}</div>
                    </Link>
                  </td>

                  {/* Preferred Column */}
                  <td style={{ width: columnWidths[7], maxWidth: '100px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div
                        className="td-content"
                        style={{
                          minWidth: '70px',
                          width: `${columnWidths[7]}px`,
                          maxWidth: '80px',
                          textAlign: 'center',
                          color: row.preferred === 'Use them, preferred' ? '#0B8A00'
                            : row.preferred === 'Use them, not preferred' ? '#ED7014'
                              : '#D32030'
                        }}
                      >
                        {row.preferred}
                      </div>
                    </Link>
                  </td>

                  {/* Comment Column */}
                  <td style={{ width: columnWidths[8], maxWidth: '700px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[8]}px`, maxWidth: '700px' }}>{row.comment}</div>
                    </Link>
                  </td>

                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SupplierListTable;