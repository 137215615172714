import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './SuppliersProfile.module.css';
import BackBtn from '../../../assets/BackBtn.svg';
import Dropdown from '../../../Components/DropDownBox/Dropdown';
import AdminContext from '../../../Context/adminContext';
import { useMsal } from "@azure/msal-react";
import EditIcon from '../../../assets/Edit.svg';
import { Box, CircularProgress } from '@mui/material';
import SubcontractorTaskTable from '../../../Sections/supplier/subcontractorTaskTable';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import { useSelector, useDispatch } from "../../../redux/store";
import { fetchRunnerOptions, getSubcontractorProjects } from "../../../redux/slices/suppliers";
import { setShowSuccess } from "../../../redux/slices/project";
import EditDiv from '../../../Components/EditDiv/EditDiv';

function SuppliersProfile() {
  const API_URL = process.env.REACT_APP_API_URL;
  const supplierID = parseInt(window.location.hash.substring(1));

  const { enqueueSnackbar } = useSnackbar();
  const { accounts } = useMsal();
  const account = accounts[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jobData, setJobData] = useState(null);
  const statusOptions = ['TBA', 'S3 Prep', 'S3 End', 'S4 Prep', 'S4 End', 'Finish'];

  const {
    runnerOptions,
    subcontractorProjects,
  } = useSelector((state) => state.suppliers);

  const { showSuccess } = useSelector((state) => state.project);

  const [loading, setLoading] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [runnerFilter, setRunnerFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState([]);

  const [currentData, setCurrentData] = useState(null);
  const [displayEditDiv, setDisplayEditDiv] = useState(false);
  const [supplierEditInfo, setSupplierEdit] = useState([]);
  const [editType, setEditType] = useState('UPDATE');
  const editable = useContext(AdminContext);

  // Fetch data
  const fetchDataRecords = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/suppliers/supplierProfile/supplierInfo?supplierID=${supplierID}`);
      setJobData(result.data);
      setSupplierEdit(result.data.UpdateSupplierInfo);
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  useEffect(() => {
    dispatch(fetchRunnerOptions());
    dispatch(getSubcontractorProjects(supplierID));
    fetchDataRecords();
  }, [dispatch]);

  useEffect(() => {
    if (showSuccess) {
      enqueueSnackbar('Successfully Added!', { variant: "success" });
      // Reset immediately after showing notification
      dispatch(setShowSuccess(false));
    }
  }, [showSuccess, dispatch]);

  const handleUpdateClick = async (DataTitle, selectedValues) => {
    // console.log('DataTitle, selectedValues', DataTitle, selectedValues)
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/api/suppliers/UpdatingInformation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Action: editType,
          SectionName: DataTitle,
          UpdatedValues: selectedValues,
          email: account.username,
          RowID: supplierID,
        }),
      })

      if (response.ok) {
        const data = await response.json();
        setApiResponse(data.message);
      } else {
        setApiResponse('Error updating supplier (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in supplier Info Update:", error);
    } finally {
      setIsResponseShown(true);
      setLoading(false);
      fetchDataRecords();
    }
  };

  const handleEdit = (data) => () => {
    setEditType('UPDATE')
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  const handleSearch = (event) => {
    setInputValue(event.target.value);
  };

  // Handle runner filter
  const handleRunnerFilter = (runner) => {
    setRunnerFilter(runner);
  };

  // Handle status filter
  const handleStatusFilter = (statuses) => {
    setStatusFilter(statuses);
  };

  // Filter subcontractorProjects based on search term, runner, and status
  const filteredData = Array.isArray(subcontractorProjects)
    ? subcontractorProjects.filter((row) => {
      const searchLower = inputValue.toLowerCase();

      const matchesSearch =
        row.projectAddress.toLowerCase().includes(searchLower) ||
        row.runner.toLowerCase().includes(searchLower) ||
        row.taskOutsourced.toLowerCase().includes(searchLower);

      const matchesRunner = runnerFilter === 'All' || row.runner === runnerFilter[0];
      const matchesStatus = statusFilter.length === 0 || statusFilter.includes(row.status);

      return matchesSearch && matchesRunner && matchesStatus;
    })
    : [];

  return (
    <div className={styles.SuppliersProfileContent}>
      <div className={styles.SupplierBaseInfo}>
        <div className={styles.BackBtn} onClick={() => navigate(-1)}>
          <img src={BackBtn} alt='Back' />
        </div>
        <div className={styles.SupplierName}>
          {jobData ? `${jobData.company}` : "Loading..."}
          {editable && jobData &&
            <img
              className={`${styles.editIcon} ${styles.Name}`}
              onClick={handleEdit(supplierEditInfo)}
              src={EditIcon}
              alt="EditIconName"
            />
          }
        </div>
        <div className={styles.SupplierServices}>
          {jobData ? `${jobData.services}` : "--"}
        </div>
        <div className={styles.ProjectTags}>
          <li className={styles.PlaceHolderBubble} />

          <div className={styles.LeftAlignment}>
            {/* Status Bubble */}
            {/* {jobData && (
              <React.Fragment>
                {jobData.status && (
                  <li className={styles.StatusBubble}>{jobData.status}</li>
                )}
              </React.Fragment>
            )} */}
          </div>
        </div>
      </div>

      <div className={styles.SuppliersProfileScrollableArea}>
        <div className={styles.ProjectInformation} style={{ display: 'flex', justifyContent: 'space-between' }}>

          <div className={styles.ProjectDetails} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1' }}>

            <div className={styles.ProjectStageInfo}>
              <div className={styles.HorBlock1}>
                {jobData && jobData.type == 'Subcontractor' ?
                  <React.Fragment>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.HoursOfWork} ${styles.FieldTitle}`} style={{ marginLeft: '12px' }}>Hours of work:</div>
                      <span className={styles.InfoText}>{jobData && jobData.working_hours ? jobData.working_hours : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.HourlyRate} ${styles.FieldTitle}`}>Cost for services:</div>
                      <span className={styles.InfoText}>{jobData && jobData.hourly_rate ? `£${jobData.hourly_rate} p/h` : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.Name} ${styles.FieldTitle}`} style={{ marginLeft: '28px' }}>Key Contact:</div>
                      <span className={styles.InfoText}>{jobData && jobData.name ? jobData.name : '--'}</span>
                    </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.PhoneNo} ${styles.FieldTitle}`}>Phone No:</div>
                      <span className={styles.InfoText}>{jobData && jobData.phone ? jobData.phone : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.Location} ${styles.FieldTitle}`} style={{ marginLeft: '10.5px' }}>Website:</div>
                      <span className={styles.InfoText}>{jobData && jobData.website ? jobData.website : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.Email} ${styles.FieldTitle}`} style={{ marginLeft: '25px' }}>Email:</div>
                      <span className={styles.InfoText}>{jobData && jobData.email ? jobData.email : '--'}</span>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>

            <div className={styles.ProjectStats}>
              <div className={styles.HorBlock1} style={{ width: '46%' }}>
                {jobData && jobData.type == 'Subcontractor' ?
                  <React.Fragment>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.Email} ${styles.FieldTitle}`} style={{ marginLeft: '60px' }}>Email:</div>
                      <span className={styles.InfoText}>{jobData && jobData.email ? jobData.email : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.Invoices} ${styles.FieldTitle}`} style={{ marginLeft: '42px' }}>Invoices: </div>
                      <span className={styles.InfoText}>{jobData && jobData.invoices ? jobData.invoices : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.PaymentTerms} ${styles.FieldTitle}`} >Payment Terms: </div>
                      <span className={styles.InfoText}>
                        {jobData && jobData.payment_terms ? jobData.payment_terms : '--'}
                      </span>
                    </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.JobFee} ${styles.FieldTitle}`}> Main Contact: </div>
                      <span className={styles.InfoText}>{jobData && jobData.name ? jobData.name : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.JobType} ${styles.FieldTitle}`} style={{ marginLeft: '23px' }}>Comment: </div>
                      <span className={styles.InfoText}>{jobData && jobData.comment ? jobData.comment : '--'}</span>
                    </div>
                    <div className={styles.FieldContainer}>
                      <div className={`${styles.JobType} ${styles.FieldTitle}`} style={{ marginLeft: '25px' }}>Preferred: </div>
                      <span className={styles.InfoText}>
                        {jobData && jobData.preferred ? jobData.preferred : '--'}
                      </span>
                    </div>
                  </React.Fragment>}
              </div>
            </div>
          </div>
        </div>
        {jobData?.type === "Subcontractor" ? (
          loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100% - 20%)' }}>
              <CircularProgress size={65} />
            </Box>
          ) : (
            <React.Fragment>
              <div className={styles.SubTitle}>Project List</div>
              <div style={{ marginBottom: '24px' }}>
                <div className='ProjectTableFilters'>
                  <div className='StatusNdTeam'>
                    <div className='ProjectTeamFilter'>
                      <label className='FilterLabel' htmlFor='RunnerLabel' style={{ fontSize: '12px' }}>Runner:</label>
                      <Dropdown
                        id='RunnerLabel'
                        options={runnerOptions}
                        initialSelectedValue={['All', ...runnerFilter]}
                        onValueChange={handleRunnerFilter}
                        disabled={loading}
                        PopUp={false}
                      />
                    </div>

                    <div className='ProjectStatusFilter'>
                      <label className='FilterLabel' htmlFor='StatusLabel' style={{ fontSize: '12px' }}>Status:</label>
                      <Dropdown
                        id='StatusLabel'
                        options={statusOptions}
                        initialSelectedValue={statusFilter}
                        onValueChange={handleStatusFilter}
                        disabled={loading}
                        PopUp={false}
                        mode='multi'
                      />

                    </div>
                  </div>
                  <div className='ProjectListSearch'>
                    <SearchBar
                      value={inputValue}
                      onChange={handleSearch}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>

              <div style={{ overflowX: 'auto', maxWidth: '80vw', margin: '0 auto' }}>
                <SubcontractorTaskTable
                  subcontractorId={supplierID}
                  subcontractorName={jobData?.company}
                  rowData={filteredData}
                  isAdmin={editable}
                />
              </div>


            </React.Fragment>
          )
        ) : null}

        {displayEditDiv && <EditDiv
          data={currentData}
          handleUpdateClick={handleUpdateClick}
          setDisplayEditDiv={setDisplayEditDiv}
          actionType={editType}
        />}

        {isResponseShown && (
          <div className={styles.ProjectProfileOverlay}>
            <div className={styles.ProjectProfileResponseBox}>
              <div
                style={{
                  fontSize: 16,
                  fontFamily: "PT Sans",
                  textAlign: "center",
                  margin: "20px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
                dangerouslySetInnerHTML={{ __html: apiResponse }}
              />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className={styles.ProjectProfileButton}
                  onClick={() => setIsResponseShown(false)}
                >
                  OK
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SuppliersProfile;