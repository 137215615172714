import React, { useEffect } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Grid, TextField } from "@mui/material";
import Dropdown from '../DropDownBox/Dropdown';
import { Form, FormikProvider } from "formik";
import { useMsal } from "@azure/msal-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";
import DatePicker from "../DatePicker/DatePicker";

import { useSelector, useDispatch } from '../../redux/store';
import { getProjectPhases } from "../../redux/slices/project";

const TimesheetSubconForm = ({ projectNumber, subcontractorName, projectAddress, onClose, onSubmit }) => {
    const { accounts } = useMsal();
    const account = accounts[0];

    const dispatch = useDispatch();

    const { phaseList } = useSelector(state => state.project);
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        if (projectNumber) {
            dispatch(getProjectPhases(projectNumber));
        }
    }, [dispatch, projectNumber]);

    const handleDateChange = (fieldName, date) => {
        if (date && date.startDate) {
            const startDate = new Date(date.startDate);
            const formattedDate = startDate.toISOString().split('T')[0];
            formik.setFieldValue(fieldName, formattedDate);
        }
    };

    const initialValues = {
        jobNumber: projectNumber,
        subcontractor: subcontractorName,
        hours: "",
        stage: "",
        comment: "",
        date: today,
        UserEmail: account.username,
    };

    const validationSchema = Yup.object().shape({
        subcontractor: Yup.string().required("Subcontractor is required"),
        hours: Yup.number()
            .required("Hours required")
            .min(1, "Minimum 1 hour")
            .max(100, "Maximum 100 hours"),
        stage: Yup.string().required("Stage is required"),
        date: Yup.string().required("Date is required"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            onSubmit(values);
            onClose();
        },
    });

    const { touched, errors, handleSubmit } = formik;

    const renderDateField = (label, fieldName) => (
        <Grid container sx={{ width: "100%" }}>
            <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>{label}: </p>
            </Grid>
            <Grid item xs={9} sx={{ mt: 1 }}>
                <DatePicker
                    initialDate={formik.values[fieldName]}
                    onDatesChange={(date) => handleDateChange(fieldName, date)}
                    visibleIcon={true}
                />
                {touched[fieldName] && errors[fieldName] && (
                    <p style={{ color: "red" }}>{errors[fieldName]}</p>
                )}
            </Grid>
        </Grid>
    );

    return (
        <div className={styles.backGround} style={{ position: "fixed", alignItems: "flex-start" }}>
            <div className={styles.form} style={{ width: "500px" }}>
                <div className={styles.momentTitle}>MOMENT</div>
                <div className={styles.sectionTitle}>Adding time to a project for a subcontractor</div>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate style={{ width: "100%" }}>
                        {/* Job Number */}
                        <Grid container sx={{ width: "100%", mt: 2 }}>
                            <Grid item xs={3} sx={{ pr: 3 }}>
                                <p className={styles.dataTitleV2}>Job Number: </p>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    value={formik.values.jobNumber}
                                    inputProps={{
                                        style: {
                                            color: formik.values.jobNumber ? "black" : "inherit", // Text black only if enabled
                                            cursor: "not-allowed", // Block cursor when disabled
                                        },
                                        disabled: true, // Disable the field
                                    }}
                                    sx={textfieldStyle}
                                />
                            </Grid>
                        </Grid>

                        {/* Address */}
                        <Grid container sx={{ width: "100%", mt: 2 }}>
                            <Grid item xs={3} sx={{ pr: 3 }}>
                                <p className={styles.dataTitleV2}>Address: </p>
                            </Grid>
                            <Grid item xs={9} >
                                <TextField
                                    fullWidth
                                    value={projectAddress}
                                    onChange={(e) => formik.setFieldValue("address", e.target.value)}
                                    inputProps={{
                                        style: {
                                            color: formik.values.jobNumber ? "black" : "inherit", // Text black only if enabled
                                            cursor: "not-allowed", // Block cursor when disabled
                                        },
                                        disabled: true, // Disable the field
                                    }}
                                    sx={textfieldStyle}
                                />
                            </Grid>
                        </Grid>

                        {/* Subcontractor */}
                        <Grid container sx={{ width: "100%", mt: 2 }}>
                            <Grid item xs={3} sx={{ pr: 3 }}>
                                <p className={styles.dataTitleV2}>Subcontractor: </p>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    value={formik.values.subcontractor}
                                    onChange={(e) => formik.setFieldValue("subcontractor", e.target.value)}
                                    inputProps={{
                                        style: {
                                            color: formik.values.jobNumber ? "black" : "inherit", // Text black only if enabled
                                            cursor: "not-allowed", // Block cursor when disabled
                                        },
                                        disabled: true, // Disable the field
                                    }}
                                    sx={textfieldStyle}
                                    error={Boolean(touched.subcontractor && errors.subcontractor)}
                                />
                            </Grid>
                        </Grid>

                        {/* Hours */}
                        <Grid container sx={{ width: "100%", mt: 2 }}>
                            <Grid item xs={3} sx={{ pr: 3 }}>
                                <p className={styles.dataTitleV2}>Hours: </p>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    value={formik.values.hours}
                                    onChange={(e) => formik.setFieldValue("hours", e.target.value)}
                                    placeholder="XXXX HRS"
                                    sx={textfieldStyle}
                                    error={Boolean(touched.hours && errors.hours)}
                                />
                            </Grid>
                        </Grid>

                        {/* Stage */}
                        <Grid container sx={{ width: "100%", mt: 2 }}>
                            <Grid item xs={3} sx={{ pr: 3 }}>
                                <p className={styles.dataTitleV2}>Stage: </p>
                            </Grid>
                            <Grid item xs={9} style={{ marginTop: '8px' }}>
                                <Dropdown
                                    placeholder={"Please select a stage"}
                                    options={phaseList}
                                    initialSelectedValue={formik.values.stage}
                                    onValueChange={(newValue) => formik.setFieldValue("stage", newValue)}
                                    PopUp={true}
                                    errorState={Boolean(touched.stage && errors.stage)}
                                />
                            </Grid>
                        </Grid>

                        {/* Date */}
                        {renderDateField("Date", "date")}

                        {/* Comment */}
                        <Grid container sx={{ width: "100%", mt: 2 }}>
                            <Grid item xs={3} sx={{ pr: 3 }}>
                                <p className={styles.dataTitleV2}>Comment: </p>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={formik.values.comment}
                                    onChange={(e) => formik.setFieldValue("comment", e.target.value)}
                                    placeholder="Please enter a comment if required"
                                    sx={textfieldStyle}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>

                {/* Buttons */}
                <div className={styles.updateAndCancel}>
                    <div className={styles.updateButton} onClick={handleSubmit}>
                        <div className={buttonStyles.YesButtonComponentStyling}>SUBMIT</div>
                    </div>
                    <div className={styles.cancelButton} onClick={onClose}>
                        <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
                    </div>
                </div>

                <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
            </div>
        </div>
    );
};

export default TimesheetSubconForm;