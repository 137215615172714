import React, { useState } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "../../redux/store";
import Dropdown from '../DropDownBox/Dropdown';
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";
import DatePicker from "../DatePicker/DatePicker";

const AWForm = ({ projectNumber, team, onSubmit, onClose }) => {
  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (fieldName, date) => {
    if (date && date.startDate) {
      const startDate = new Date(date.startDate); // Convert to Date object
      const formattedDate = startDate.toISOString().split('T')[0]; // Extract only the date part (yyyy-mm-dd)
      formik.setFieldValue(fieldName, formattedDate); // Set Formik value with formatted date
    }
  };

  const initialValues = {
    jobNumber: projectNumber,
    resourceRequired: "",
    issueDate: today,
    teamName: team,
    description: "",
    additionalFees: "",
    additionalFeesAmount: "",
    hourlyRate: "",
    invoiceDate: today,
  };

  const [showAdditionalQuestions, setShowAdditionalQuestions] = useState(false);

  const validateAdditionalFees = (values) => {
    const errors = {};
    if (values.additionalFees === "Yes") {
      if (!values.additionalFeesAmount) {
        errors.additionalFeesAmount = "Please specify the additional fees amount";
      }
      if (!values.hourlyRate) {
        errors.hourlyRate = "Please indicate if charged hourly";
      }
    }
    return errors;
  };

  const validationSchema = Yup.object().shape({
    resourceRequired: Yup.string()
      .required("Please list out the resources required")
      .test(
        'resource-format',
        'Each line must follow the format: Initials - Xhrs - Description (e.g., TPE - 2hrs - Engineering Check)',
        (value) => {
          if (!value) return true; // Let "required" handle empty values
          const lines = value.split('\n');
          const regex = /^[A-Z0-9]+ - \d+(\.\d+)?hrs? - .+$/i; // Regex pattern
          return lines.every(line => {
            const trimmedLine = line.trim();
            return trimmedLine && regex.test(trimmedLine);
          });
        }
      ),
    issueDate: Yup.string().required("Issue Date is required"),
    description: Yup.string().required("Description is required"),
    additionalFees: Yup.string().required("Add Fees required to be filled out"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validate: validateAdditionalFees,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      onSubmit(values);
      onClose();
    },
  });

  const { touched, errors, handleSubmit } = formik;

  const renderDateField = (label, fieldName, showField = true) => {
    if (!showField) return null;

    return (
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={3} sx={{ pr: 3 }}>
          <p className={styles.dataTitleV2}>{label}: </p>
        </Grid>
        <Grid item xs={9} sx={{ mt: 1 }}>
          <DatePicker
            initialDate={formik.values[fieldName]}
            onDatesChange={(date) => handleDateChange(fieldName, date)}
            visibleIcon={true}
          />
          {touched[fieldName] && errors[fieldName] && (
            <p style={{ color: "red" }}>{errors[fieldName]}</p>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.backGround} style={{ position: "fixed", alignItems: "flex-start" }}>
      <div className={styles.form} style={{ width: "500px" }}>
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Additional Works</div>

        <FormikProvider value={formik} handleSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Job Number: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.jobNumber}
                  type="text"
                  onChange={(e) => formik.setFieldValue("jobNumber", e?.target?.value)}
                  placeholder="Job Number"
                  inputProps={{
                    style: {
                      color: formik.values.jobNumber ? "black" : "inherit", // Text black only if enabled
                      cursor: "not-allowed", // Block cursor when disabled
                    },
                    disabled: true, // Disable the field
                  }}
                  sx={
                    textfieldStyle // Include existing styles

                  }
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Resource Required: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.resourceRequired}
                  type="text"
                  onChange={(e) => formik.setFieldValue("resourceRequired", e?.target?.value)}
                  label="e.g: 'TPE - 2hrs - Engineering Check' (per line)" // Persistent label
                  InputLabelProps={{
                    style: { fontStyle: "italic", color: "grey" },
                  }}
                  sx={textfieldStyle}
                  error={Boolean(touched.resourceRequired && errors.resourceRequired)}
                  helperText={touched.resourceRequired && errors.resourceRequired}
                />
              </Grid>
            </Grid>

            {renderDateField("Issue Date", "issueDate")}

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Description: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.description}
                  type="text"
                  onChange={(e) => formik.setFieldValue("description", e?.target?.value)}
                  placeholder="Please write a short summary/description as to what the additional works are for"
                  sx={textfieldStyle}
                  error={Boolean(touched.description && errors.description)}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Additional Fees: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <Dropdown
                  options={["Yes", "No"]}
                  initialSelectedValue={formik.values.additionalFees}
                  placeholder={"Are additional fees being charged?"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue("additionalFees", newValue);
                    setShowAdditionalQuestions(newValue === "Yes");
                  }}
                  PopUp={true}
                  errorState={Boolean(touched.additionalFees && errors.additionalFees)}
                />
              </Grid>
            </Grid>

            {showAdditionalQuestions && (
              <React.Fragment>
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={3} sx={{ pr: 3 }}>
                    <p className={styles.dataTitleV2}>Additional Fees Amount: </p>
                  </Grid>
                  <Grid item xs={9} sx={{ mt: 1 }}>
                    <TextField
                      fullWidth
                      value={formik.values.additionalFeesAmount}
                      type="number"
                      onChange={(e) => formik.setFieldValue("additionalFeesAmount", e?.target?.value)}
                      placeholder="e.g: 5000"
                      sx={textfieldStyle}
                      error={Boolean(touched.additionalFeesAmount && errors.additionalFeesAmount)}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={3} sx={{ pr: 3 }}>
                    <p className={styles.dataTitleV2}>Is this charged at hourly rate?: </p>
                  </Grid>
                  <Grid item xs={9} sx={{ mt: 1 }}>
                    <Dropdown
                      options={["Yes", "No"]}
                      initialSelectedValue={formik.values.hourlyRate}
                      placeholder={"Is this charged hourly?"}
                      onValueChange={(newValue) => formik.setFieldValue("hourlyRate", newValue)}
                      PopUp={true}
                      errorState={Boolean(touched.hourlyRate && errors.hourlyRate)}
                    />
                  </Grid>
                </Grid>

                {renderDateField("Invoice Date", "invoiceDate")}
              </React.Fragment>
            )}
          </Form>
        </FormikProvider>

        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={handleSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>SUBMIT</div>
          </div>
          <div className={styles.cancelButton} onClick={onClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
      </div>
    </div>
  );
};

export default AWForm;