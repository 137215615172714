import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Paper, Typography, Box, Select, MenuItem, FormControl } from '@mui/material';
import { ChevronDown } from 'lucide-react';

const BillingForecast = ({ 
  width = '100%',
  height = '300px',
  chartHeight = '250px',
  maxWidth = '700px',
  data,
  onYearChange // Callback prop to notify year changes
}) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const yearOptions = [
    currentYear - 2,
    currentYear - 1,
    currentYear,
    currentYear + 1
  ];

  const formatCurrency = (value) => {
    return `£${Number(value).toLocaleString('en-GB', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);

    // Notify the parent or external logic
    if (onYearChange) {
      onYearChange(year);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ p: 1, backgroundColor: 'white', border: '1px solid #ccc' }}>
          <Typography variant="body2" sx={{ fontSize: '11px', fontWeight: 'bold' }}>
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography 
              key={index} 
              variant="body2" 
              sx={{ fontSize: '11px', color: entry.color }}
            >
              {`${entry.name}: ${formatCurrency(entry.value)}`}
            </Typography>
          ))}
        </Paper>
      );
    }
    return null;
  };

  return (
    <Paper 
      elevation={1} 
      sx={{ 
        width: width, 
        height: height,
        maxWidth: maxWidth,
        marginTop: 2,
        mb: 2, 
        p: 2,
        '& .recharts-wrapper': {
          fontSize: '12px'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        mb: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl size="small" sx={{ minWidth: 90 }}>
            <Select
              value={selectedYear}
              onChange={handleYearChange} // Handle year changes
              IconComponent={ChevronDown}
              sx={{
                '& .MuiSelect-select': {
                  py: 0.5,
                  pr: 4,
                  fontSize: '0.875rem'
                }
              }}
            >
              {yearOptions.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ height: chartHeight, width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              tick={{ fontSize: 11 }}
              interval={0}
            />
            <YAxis
              tickFormatter={formatCurrency}
              width={80}
              tick={{ fontSize: 11 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ fontSize: 11 }}/>
            <Bar 
              dataKey="planned" 
              name="Planned" 
              fill="#8884d8"
              radius={[2, 2, 0, 0]}
            />
            <Bar 
              dataKey="invoiced" 
              name="Invoiced" 
              fill="#82ca9d"
              radius={[2, 2, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default BillingForecast;
