import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from "axios";
import { StarRateTwoTone } from "@mui/icons-material";

// Redux

// ----------------------------------------------------------------------

let initialState = {
  raiseInvoiceDetails: {},
  raiseInvoicePhases: [],
  raiseInvoiceHourlyRates: [],
  raiseInvoiceAssignedBilling: [],
  statusActivityMap: [],
  invLogbookData: [],
  raiseInvoiceDetailsLoading: false,
  raiseInvoicePhaseLoading: false,
  raiseInvoiceHourlyRateLoading: false,
  raiseInvoiceAssignedBillingLoading: false,
  isinvoiceLogBookLoading: true,
  isSubmitting: false,
  invPostLoading: false,
  error: null,
  showError: false,
  apiMessage: null,
  showSuccess: false,
};

export const slice = createSlice({
  name: "raiseInvoice",
  initialState,
  reducers: {
    setRaiseInvoiceDetails(state, action) {
      state.raiseInvoiceDetails = action.payload;
      state.raiseInvoiceDetailsLoading = false;
    },
    setRaiseInvoicePhases(state, action) {
      state.raiseInvoicePhases = [];
      state.raiseInvoicePhases = action.payload;
      state.raiseInvoicePhaseLoading = false;
    },

    setRaiseInvoiceHourlyRate(state, action) {
      state.raiseInvoiceHourlyRates = action.payload;
      state.raiseInvoiceHourlyRateLoading = false;
    },

    setRaiseInvoiceAssignedBilling(state, action) {
      state.raiseInvoiceAssignedBilling = action.payload;
      state.raiseInvoiceAssignedBillingLoading = false;
    },

    setInvoiceLogbookFilters(state, action) {
      state.statusActivityMap = action.payload;
    },
    setInvoiceLogbookData(state, action) {
      state.invLogbookData = action.payload;
    },

    setRaiseInvDetailsLoading(state, action) {
      state.raiseInvoiceDetailsLoading = action.payload;
    },
    setRaiseInvPhasesLoading(state, action) {
      state.raiseInvoicePhaseLoading = action.payload;
    },

    setRaiseInvHourlyRateLoading(state, action) {
      state.raiseInvoiceHourlyRates = action.payload;
    },

    setRaiseInvAssignedBillingLoading(state, action) {
      state.raiseInvoiceAssignedBillingLoading = action.payload;
    },
    setInvLogbookLoading(state, action) {
      state.isinvoiceLogBookLoading = action.payload;
    },

    setInvPostLoading(state, action) {
      state.invPostLoading = action.payload;
    },

    hasError(state, action) {
      state.raiseInvoiceDetailsLoading = false;
      state.raiseInvoicePhaseLoading = false;
      state.raiseInvoiceHourlyRates = false;
      state.raiseInvoiceAssignedBillingLoading = false;
      state.isinvoiceLogBookLoading = false;
      state.showError = true;
      state.error = action.payload;
    },

    showApiMessage(state, action) {
      state.apiMessage = action.payload;
    },

    showSuccess(state, action) {
      state.showError = false;
      state.apiMessage = null;
      state.showSuccess = action.payload;
    },

    resetApiMessage(state) {
      state.showError = false;
      state.apiMessage = null;
      state.showSuccess= false;
    },

    clearInvoice(state, action) {
      state.raiseInvoiceDetails = {};
      state.raiseInvoicePhases = [];
      state.raiseInvoiceHourlyRates = [];
      state.raiseInvoiceAssignedBilling = [];
      state.statusActivityMap = [];
      state.invLogbookData = [];
    },
  },
});

export const { hasError, resetApiMessage } = slice.actions;
export default slice.reducer;

export function getRaiseInvoiceDetails(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvDetailsLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/detail?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      if (response.data.success) {
        dispatch(slice.actions.setRaiseInvoiceDetails(response.data.data));
      }
      dispatch(slice.actions.setRaiseInvDetailsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getRaiseInvoicePhases(jobNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvPhasesLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/phases/${jobNumber}`
      );
      dispatch(slice.actions.setRaiseInvoicePhases(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getRaiseInvoiceHourlyRates(jobNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvHourlyRateLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/hourly/rate/${jobNumber}`
      );
      dispatch(slice.actions.setRaiseInvoiceHourlyRate(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getRaiseInvoiceAssignedBilling(jobNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvAssignedBillingLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/billing/${jobNumber}`
      );
      dispatch(
        slice.actions.setRaiseInvoiceAssignedBilling(response.data.data)
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getLogBookFilters() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/invoiceProfile/LogBookFilters`
      );
      dispatch(slice.actions.setInvoiceLogbookFilters(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getLogBook(invoiceID) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setInvLogbookLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/invoiceProfile/invoiceLogBook?invoiceID=${invoiceID}`
      );
      dispatch(slice.actions.setInvoiceLogbookData(response.data.data));
      dispatch(slice.actions.setInvLogbookLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
      dispatch(slice.actions.setInvLogbookLoading(false));
    }
  };
}

export function postLogBook(logBookbody, url) {
  const API_URL = process.env.REACT_APP_API_URL;

  const CommentLog = {
    invoiceID: logBookbody.invoiceID,
    status: logBookbody.status,
    activity: logBookbody.activity,
    comment: logBookbody.comment,
    email: logBookbody.email,
  };

  return async () => {
    dispatch(slice.actions.setInvLogbookLoading(true));
    // NOTE TO HASSAN: Potentially include other loading variables to true for the invoice profile page
    try {
      const response = await axios.post(
        `${API_URL}/api/invoice/invoiceProfile/submitInvoiceLogbook`,
        CommentLog,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (url) {
        window.location.href = url;
        return;
      }
      // NOTE TO HASSAN: Potentially include other loading variables to false for the invoice profile page
      // RELOAD ALL DATA for the page, call other APIs present in the page
      dispatch(getLogBook(logBookbody.invoiceID));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
      dispatch(slice.actions.setInvLogbookLoading(false));
    }
  };
}

export function postDraftInvoice(body, logBookbody) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setInvPostLoading(true));
    try {
      const response = await axios.post(
        `${API_URL}/api/invoice/project/invoicing/draft/invoice`,
        body
      );
      dispatch(slice.actions.setInvPostLoading(false));

      if (response.data.success) {
        const logBody = logBookbody;
        logBody.invoiceID = response.data.data.invoiceID;
        const url = `${"raise-invoice"}?jobNumber=${
          body.jobNumber
        }&invoiceNumber=${response.data.data.invoiceID}&status=${
          logBookbody.status
        }`;
        dispatch(postLogBook(logBody,url));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.showApiMessage(error.response.data.message));
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setInvPostLoading(false));
    }
  };
}

export function putDraftInvoice(body, logBookbody, type) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async (dispatch) => {
    dispatch(slice.actions.setInvPostLoading(true));
    try {
      // Create a new object that does not include the defaultBillPayerID key
      const { defaultBillPayerID, ...bodyWithoutDefaultBillPayerID } = body;
      const URL_CALL =
        type == "credit"
          ? `${API_URL}/api/invoice/project/invoicing/draft/credit/note`
          : `${API_URL}/api/invoice/project/invoicing/draft/invoice`;
      const response = await axios.put(
        URL_CALL,
        bodyWithoutDefaultBillPayerID // Use the new object without defaultBillPayerID
      );

      if (response.data.success) {
        dispatch(slice.actions.showSuccess(true));
        const url = `${"raise-invoice"}?jobNumber=${
          body.jobNumber
        }&invoiceNumber=${body.invoiceNumber}&status=${logBookbody.status}`;
        dispatch(postLogBook(logBookbody, url)); // Assume this is another action creator that handles log book entries
        // window.location.href = `${"raise-invoice"}?jobNumber=${
        //   body.jobNumber
        // }&invoiceNumber=${body.invoiceNumber}&status=${logBookbody.status}`;
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    } finally {
      dispatch(slice.actions.setInvPostLoading(false));
    }
  };
}

export function updateInvoiceAddress(body, logBookbody) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setInvPostLoading(true));
    try {
      const response = await axios.post(
        `${API_URL}/api/invoice/project/invoicing/change/address`,
        body
      );
      dispatch(postLogBook(logBookbody, ""));
      dispatch(slice.actions.showSuccess(true));
      dispatch(slice.actions.setInvPostLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
      dispatch(slice.actions.setInvPostLoading(false));
    }
  };
}

export function getDraftedInvoicePhases(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvPhasesLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/draft/phases?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      dispatch(slice.actions.setRaiseInvoicePhases(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getDraftedCreditPhases(jobNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvPhasesLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/credit/note/phases/${jobNumber}
        `
      );
      dispatch(slice.actions.setRaiseInvoicePhases(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getDraftedInvoiceHourlyRates(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvHourlyRateLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/draft/hourly/rate?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      dispatch(slice.actions.setRaiseInvoiceHourlyRate(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getDraftedInvoiceAssignedBilling(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvAssignedBillingLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/draft/invoicing/billing?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      dispatch(
        slice.actions.setRaiseInvoiceAssignedBilling(response.data.data)
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getRaisedInvoicePhases(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvPhasesLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/view/phases?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      dispatch(slice.actions.setRaiseInvoicePhases(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getRaisedInvoiceHourlyRates(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvHourlyRateLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/view/hourly/rate?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      dispatch(slice.actions.setRaiseInvoiceHourlyRate(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function getRaisedInvoiceAssignedBilling(jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setRaiseInvAssignedBillingLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/invoicing/view/invoicing/billing?${
          jobNumber ? `jobNumber=${jobNumber}` : ``
        }&${invoiceNumber ? `invoiceNumber=${invoiceNumber}` : ``}
        `
      );
      dispatch(
        slice.actions.setRaiseInvoiceAssignedBilling(response.data.data)
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
    }
  };
}

export function updateInvoicePaid(body, logBookbody) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setInvPostLoading(true));
    try {
      const response = await axios.post(
        `${API_URL}/api/invoice/project/invoicing/pay`,
        body
      );
      if (response.data.success) {
        dispatch(postLogBook(logBookbody,""));
      }
      dispatch(slice.actions.setInvPostLoading(false));
      dispatch(slice.actions.showSuccess(true));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
      dispatch(slice.actions.setInvPostLoading(false));
    }
  };
}

export function generateInvoice(invoiceType, jobNumber, invoiceNumber) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(slice.actions.setInvPostLoading(true));
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/invoiceProfile/generate?invoiceType=${invoiceType}&jobNumber=${jobNumber}&invoiceNumber=${invoiceNumber}`,
        {
          responseType: 'blob', // Setting responseType to 'blob' to handle binary data.
        }
      );


      console.log(response.headers['content-disposition'])
      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'Invoice.docx';
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const matches = /filename="([^"]*)"/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1];
        }
      }

      // Create a Blob object from the response data
      const file = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      // Generate a URL for the Blob object
      const fileURL = URL.createObjectURL(file);

      // Create a temporary anchor element to enable file download
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', filename); // Use the extracted filename
      tempLink.style.display = 'none';
      document.body.appendChild(tempLink);
      tempLink.click(); // Trigger the download
      document.body.removeChild(tempLink);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(fileURL);

      // Dispatch a success action, could update state to show download was successful
      dispatch(slice.actions.setInvPostLoading(false));
      dispatch(slice.actions.showSuccess(true));
    } catch (error) {
      // Log error if the download process fails and dispatch an error state update
      console.error('Error during invoice generation:', error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.showApiMessage(error.response.data.message));
      dispatch(slice.actions.setInvPostLoading(false));
    }
  };
}

export function refreshSuccess() {
  return async () => {
    dispatch(slice.actions.showSuccess(false));
  };
}

export function clearInvoiceData() {
  return async () => {
    dispatch(slice.actions.clearInvoice());
  };
}
