import React from 'react';
import styles from './ConfirmationPopUp.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';

const ConfirmationPopUp = ({ isVisible, onClose, onConfirm, message }) => {
    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <p className={styles.text}>{message || "Are you sure?"}</p>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <div style={{ width: '74px', height: '25px' }}>
                        <button onClick={onConfirm} className={buttonStyles.YesButtonComponentStyling}>
                            CONFIRM
                        </button>
                    </div>
                    <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                        <button onClick={onClose} className={buttonStyles.NoButtonComponentStyling}>
                            CANCEL
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopUp;
