import React, { useState, useEffect } from 'react';
import styles from './EditDiv.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import DropDown from '../DropDownBox/Dropdown';
import BlueLogo from '../../assets/BlueLogo.png';
import DatePicker from '../DatePicker/DatePicker';
import CommentBox from '../CommentBox/CommentBox';
import TickBox from '../TickBox/TickBox';
import TwoButtonsInput from '../TwoButtonsInput/TwoButtonsInput';

function EditDiv({data, handleUpdateClick, setDisplayEditDiv, actionType, required = false}) {
  const [selectedValues, setSelectedValues] = useState({});
  const [checkBoxStates, setCheckBoxStates] = useState({});
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [total, setTotal] = useState(0);

  // Initialize checkbox states and selectedValues from data.DataEdit
  useEffect(() => {
    const initialCheckBoxStates = {};
    const initialSelectedValues = {};
    data.DataEdit.forEach(({ dataName, checked, data: fieldData }) => {
      if (checked !== undefined) {
        initialCheckBoxStates[dataName] = checked;
      }
      if (fieldData !== undefined) {
        initialSelectedValues[dataName] = fieldData;
      }
    });
    setCheckBoxStates(initialCheckBoxStates);
    setSelectedValues(initialSelectedValues);
  }, [data]);

  // Calculate total from both fieldData and selectedValues
  useEffect(() => {
    if (data.DataEditTitle === "Edit Allowances") {
      const sum = data.DataEdit.reduce((acc, { dataName, data: fieldData }) => {
        // Skip the key that should be excluded (e.g., 'allowance')
        if (dataName === 'Total Allowance' || dataName === 'Holiday Allowance') {
          return acc;
        }
  
        // Use selectedValues if available, otherwise use fieldData
        const value = selectedValues[dataName] !== undefined ? selectedValues[dataName] : fieldData;
        const numVal = Number(value);
  
        return acc + (isNaN(numVal) ? 0 : numVal);
      }, 0);
      setTotal(sum);
    }
  }, [data, selectedValues]);  

  const updateSelectedValue = (dataName) => (newValue) => {
    console.log(`Updating ${dataName} with ${newValue}`);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dataName]: newValue
    }));
  };

  const validateAndSubmit = () => {
    let invalids = [];
    
    if (required) {
      data.DataEdit.forEach(({editType, dataName}) => {
        if (data.DataEditTitle === 'New Client') {
          if ((editType === 'TextBox' || editType === 'LargeTextBox') && !['Address 2', 'Town', 'Comment'].includes(dataName) && !selectedValues[dataName]) {
            invalids.push(dataName);
          }
        } else {
          if ((editType === 'TextBox' || editType === 'LargeTextBox') && !selectedValues[dataName]) {
            invalids.push(dataName);
          }
        }
      });
    }
    
    if (invalids.length > 0) {
      setInvalidInputs(invalids);
      const timer = setTimeout(() => {
        setInvalidInputs([]);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      // Add total to selectedValues if it's Edit Allowances
      const valuesToSubmit = data.DataEditTitle === "Edit Allowances" 
        ? { ...selectedValues, total: total.toFixed(2) }
        : selectedValues;
        
      handleUpdateClick(data.DataEditTitle, valuesToSubmit);
      handleClose();
    }
  };

  const handleCheckBoxClick = (dataName, checked) => {
    setCheckBoxStates({
      ...checkBoxStates,
      [dataName]: !checked
    });
  };

  const handleClose = () => {
    setDisplayEditDiv(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.backGround} style={{ position: 'fixed' }} onClick={handleClose}>
      <div 
        className={styles.form} 
        style={{ 
          width: data.DataEditTitle === "Invoice Request" ? "auto" : 
                data.DataEditTitle === "Edit Invoice Request" ? "401px" : "418px" 
        }} 
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>{data.DataEditTitle}</div>
        
        <ul className={styles.List}>
          {data.DataEdit.map(({editType, dataName, data: fieldData, options, checked, colour}) => {
            switch(editType) {
              case 'NonEditable':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.NonEditable}>
                      {fieldData}
                    </div>
                  </li>
                );
              case 'Date':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      {(() => {
                        if (dataName === "Invoice Date") {
                          return <DatePicker dataName={dataName} initialDate={fieldData} onDatesChange={updateSelectedValue(dataName)} keepEmpty={false} visableIcon={true} />;
                        } else if (fieldData && fieldData.length === 0) {
                          return <DatePicker dataName={dataName} initialDate={fieldData} onDatesChange={updateSelectedValue(dataName)} keepEmpty={true} />;
                        } else {
                          return <DatePicker dataName={dataName} initialDate={fieldData} onDatesChange={updateSelectedValue(dataName)} />;
                        }
                      })()}
                    </div>
                  </li>
                );
              case 'Btns&Other':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <TwoButtonsInput FirstBtn={fieldData.BtnOneValue} SecondBtn={fieldData.BtnTwoValue} OtherPlaceHolder={fieldData.OtherPlaceHolder} hexCode={fieldData.BtnColour} onValueChange={updateSelectedValue(dataName)} />
                    </div>
                  </li>
                );
              case 'DropDown':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <DropDown options={options} initialSelectedValue={fieldData} onValueChange={updateSelectedValue(dataName)} PopUp={true} />
                    </div>
                  </li>
                );
              case 'MultiDropDown':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <DropDown options={options} initialSelectedValue={fieldData} onValueChange={updateSelectedValue(dataName)} PopUp={true} mode='multi' />
                    </div>
                  </li>
                );
              case 'CheckBox':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <TickBox 
                        type={checkBoxStates[dataName] || false} 
                        tickClick={() => {
                          updateSelectedValue(dataName)(!checkBoxStates[dataName]); 
                          handleCheckBoxClick(dataName, checkBoxStates[dataName]);
                        }} 
                        coloureis={colour}
                      />
                    </div>
                  </li>
                );
              case 'NumberTextBox':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.TextBoxHolder}>
                      <CommentBox initialData={fieldData} onValueChange={updateSelectedValue(dataName)} integerOnly={true} />
                    </div>
                  </li>
                );
              case 'TextBox':
              case 'LargeTextBox':
                return (
                  <li key={dataName} className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles[`${editType}Holder`]}>
                      <CommentBox initialData={fieldData} onValueChange={updateSelectedValue(dataName)} invalid={invalidInputs.includes(dataName)} />
                    </div>
                  </li>
                );
              default:
                return null;
            }
          })}
          
          {/* Total row for Edit Allowances */}
          {data.DataEditTitle === "Edit Allowances" && (
            <li className={styles.dataRow}>
              <div className={styles.dataTitle}>
                New Total:
              </div>
              <div className={styles.NonEditable}>
                {total.toFixed(2)}
              </div>
            </li>
          )}
        </ul>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={validateAndSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/>
      </div>
    </div>
  );
}

export default EditDiv;