import React, { useState, useEffect } from "react";
import styles from "../../Components/Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";
import Checkbox from "@mui/material/Checkbox";
import MoreOptionsV2 from "../../Components/MoreOptions/MoreOptionsV2.js";
import { TextField, Select, MenuItem } from "@mui/material";
import DropDownV2 from "../../Components/DropDownBox/DropdownV2";
import textfieldStyle from "../../GlobalStyles/styles";
import "./invoice.css";
import { useSelector, useDispatch } from "../../redux/store";
const PhaseTable = ({
  jsonData,
  headerMap = {},
  clickableRowAdd = false,
  handleRowEdit,
  setRowData,
  rowData,
  disabled = false,
}) => {
  const { isSubmitting, raiseInvoicePhases } = useSelector(
    (state) => state.raiseInvoice
  );
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (raiseInvoicePhases?.length > 0) {
      setRowData(raiseInvoicePhases);
    }
    if (raiseInvoicePhases?.length == 0) {
      setRowData([]);
    }
  }, [raiseInvoicePhases]);

  const resolvedHeaderMap = headerMap;
  const headers = Object.keys(resolvedHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  const handleRowUpdate = (key, comingRow, value) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        if (item.phaseID === comingRow.phaseID) {
          return { ...item, [key]: value };
        } else {
          return item;
        }
      })
    );
  };

  const handleSelectAll = (selectAll) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        return { ...item, isSelected: selectAll };
      })
    );
    setSelectAll(selectAll);
  };

  const handleVatUpdate = (key, comingRow, value) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        if (item.phaseID === comingRow.phaseID) {
          return {
            ...item,
            [key]: value,
          };
        } else {
          return item;
        }
      })
    );
  };

  const calculateVat = (row) => {
    return row.invoiceAmount * (row.vat / 100);
  };

  const calculateGross = (row) => {
    return row.invoiceAmount
      ? row.invoiceAmount * (row.vat / 100) + parseFloat(row.invoiceAmount)
      : 0;
  };

  const calculateTotalInvAmt = () => {
    return rowData
      .filter((data) => data.isSelected)
      .reduce((acc, cur) => {
        return parseFloat(acc) + parseFloat(cur.invoiceAmount);
      }, 0);
  };

  const calculateTotalVat = () => {
    return rowData
      .filter((data) => data.isSelected)
      .reduce((acc, cur) => {
        return acc + calculateVat(cur);
      }, 0);
  };

  const calculateTotalGross = () => {
    return rowData
      .filter((data) => data.isSelected)
      .reduce((acc, cur) => {
        return acc + calculateGross(cur);
      }, 0);
  };
  const rowClasses = `${styles.tableRow} ${clickableRowAdd ? styles.rowHoverHighlight : ""
    }`;

  return (
    <div
      className={styles.tableContainer}
      style={{
        maxHeight: "300px",
        height: "300px",
        overflowY: "auto",
        overflowX: "hidden",
        background: "inherit",
      }}
    >
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";

              if (index === 0) {
                return (
                  <th className={styles.th} colspan="1">
                    <Checkbox
                      disabled={disabled}
                      checked={selectAll}
                      style={{ pl: 20 }}
                      onChange={(e) => handleSelectAll(!selectAll)}
                    />
                  </th>
                );
              }
              if (
                index == 2 ||
                index == 4 ||
                index == 3 ||
                index == 4 ||
                index == 5 ||
                index == 6 ||
                index == 7
              ) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                    //   onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {rowData?.length === 0 ? (
            <tr>
              <td
                colSpan="8"
                style={{
                  textAlign: 'center',
                  color: '#4a60a8',
                  fontWeight: 'bold',
                }}
              >
                There are no phases avaliable or this is an invoice from before Moment was managing invoices - refer to original PDF on SharePoint.
              </td>
            </tr>
          ) : (rowData?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              style={{ background: "white" }}
            >
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "3%" }}
                className={styles.td}
              >
                <div>
                  <Checkbox
                    disabled={disabled}
                    checked={row.isSelected}
                    style={{ pl: 20 }}
                    onChange={(e) =>
                      handleRowUpdate("isSelected", row, !row.isSelected)
                    }
                  />
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "7%" }}
                className={styles.td}
              >
                <div>{row.phaseName}</div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "1%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>£{row.phaseAmount}</div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "15%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>
                  <TextField
                    disabled={disabled}
                    value={row.invoiceAmount ? row.invoiceAmount : ""}
                    type="number"
                    onChange={(e) =>
                      handleRowUpdate("invoiceAmount", row, e.target.value)
                    }
                    placeholder=" Inv Amount"
                    sx={textfieldStyle}
                    error={Boolean(isSubmitting && !row.invoiceAmount)}
                  />
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "10%" }}
                className={styles.td}
              >
                <div>
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    // value={age}
                    // label="Age"
                    value={row.vat}
                    className="select"
                    // sx={textfieldStyle}
                    defaultValue={20}
                    onChange={(e) => {
                      // handleRowUpdate("vat", row, e.target.value);
                      handleVatUpdate("vat", row, e.target.value);
                    }}
                    disabled={disabled}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          padding: 0,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px 8px 8px 8px",
                          border: "1px solid #3C96D4 !important",
                          padding: 0.2,

                          m: 0,
                        },
                        "& .MuiMenuItem-root": {
                          padding: 0,

                          backgroundColor: "#fff",

                          paddingLeft: 2,
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: `#EAF2F9`,
                        },
                      },
                    }}
                  >
                    <MenuItem className="selectItem" value={0}>
                      0%
                    </MenuItem>
                    <MenuItem className="selectItem" value={5}>
                      5%
                    </MenuItem>
                    <MenuItem className="selectItem" value={20}>
                      20%
                    </MenuItem>
                  </Select>
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "4%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>
                  {" "}
                  {calculateVat(row).toLocaleString("en-UK", {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 2,
                  })}
                  {/* {parseFloat(calculateVat(row)).toLocaleString("en-UK", {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 0,
                  })} */}
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "4%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>
                  {" "}
                  {calculateGross(row).toLocaleString("en-UK", {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 2,
                  })}
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "14%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>
                  <div>
                    <Select
                      disabled={disabled}
                      renderValue={(o) => o}
                      value={row.nominalCode}
                      // label="Age"
                      className="select"
                      defaultValue={""}
                      onChange={(e) => {
                        handleRowUpdate("nominalCode", row, e.target.value);
                      }}
                      MenuProps={{
                        sx: {
                          "& .MuiPaper-root": {
                            padding: 0,
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px 8px 8px 8px",
                            border: "1px solid #3C96D4 !important",
                            padding: 0.2,

                            m: 0,
                          },
                          "& .MuiMenuItem-root": {
                            padding: 0,

                            backgroundColor: "#fff",

                            paddingLeft: 2,
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: `#EAF2F9`,
                          },
                        },
                      }}
                    >
                      <MenuItem
                        className="selectItem"
                        value={"4001 - Professional services"}
                      >
                        4001 - Professional services
                      </MenuItem>
                      <MenuItem
                        className="selectItem"
                        value={"4002 - Sun Consultant Services"}
                      >
                        4002 - Sun Consultant Services
                      </MenuItem>
                      <MenuItem
                        className="selectItem"
                        value={"4010 - Travel costs"}
                      >
                        4010 - Travel costs
                      </MenuItem>
                      <MenuItem
                        className="selectItem"
                        value={"4013 - Drainage reports"}
                      >
                        4013 - Drainage reports
                      </MenuItem>
                      <MenuItem
                        className="selectItem"
                        value={"4015 - Trial hole and exploratory works"}
                      >
                        4015 - Trial hole and exploratory works
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "50%" }}
                className={styles.td}
              >
                <TextField
                  disabled={disabled}
                  fullWidth
                  value={row.comment}
                  type="text"
                  onChange={(e) =>
                    handleRowUpdate("comment", row, e.target.value)
                  }
                  placeholder="Comment"
                  sx={textfieldStyle}
                  error={Boolean(isSubmitting && !row.comment)}
                />
              </td>
            </tr>
          )))}

          <tr className={styles.tableBottom} style={{ background: "#EAF2F9" }}>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div className={styles.RightAlign} style={{ marginTop: "10px" }}>
                Total:
              </div>
            </td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                £{calculateTotalInvAmt()}
              </div>
            </td>
            <td className={styles.td}></td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                {calculateTotalVat().toLocaleString("en-UK", {
                  style: "currency",
                  currency: "GBP",
                  maximumFractionDigits: 2,
                })}
              </div>
            </td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                {calculateTotalGross().toLocaleString("en-UK", {
                  style: "currency",
                  currency: "GBP",
                  maximumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className={styles.td}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PhaseTable;
