import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import stylies from './Sickness.module.css'
import DatePicker from '../../../Components/DatePicker/DatePicker';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import Table from "../../../Components/Table/TableV2";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AdminForm from '../../../Components/SicknessForm/AdminForm'
import Calendar from '../../../assets/Calendar.svg';
import Modal from 'react-modal';
import buttonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css'

function Sickness() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { accounts } = useMsal();
  const account = accounts[0];
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [displayAdminForm, setDisplayAdminForm] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [leaveType, setLeaveType] = useState("Cough, Cold, Flu");
  const [LengthOfLeave, setLengthOfLeave] = useState("Morning");
  const [ReturnDate, setReturnDate] = useState("Morning");
  const [comment, setComment] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formID, setFormID] = useState('');

  const getCurrentDateInFormat = () => {
    const currentDate = new Date();
    const formattedDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString();
    return formattedDate;
  };
  const getCurrentDate = () => {
    const currentDate = new Date().toISOString();
    return currentDate;
  };

  const [currentData, setCurrentData] = useState(getCurrentDate());

  const handleFocus = () => {
    setIsFocused(true);
  }
  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  }

  const [startDate, setStartDate] = useState({ startDate: getCurrentDateInFormat() });
  const [selectedValues, setSelectedValues] = useState({});

  const Length_of_Leave_options = [
    "Morning",
    "Afternoon"
  ]

  const leave_type_options = [
    "Cough, Cold, Flu",
    "Digestive/Stomach",
    "Eye Infection",
    "Compassionate",
    "Maternity",
    "Paternity",
    "Headache, Migraine",
    "Non-Work Related Injury",
    "Work Related Injury",
    "Ongoing Health Issue",
    "Psychiatric, Stress",
    "Other",
  ];

  const [SicknessLogData, setSicknessLogData] = useState("");
  const [SicknessOverviewData, setSicknessOverviewData] = useState("");
  const [NameOfSickEmployee, setNameOfSickEmployee] = useState("Aliah Anuar");

  const handleSubmit = async () => {
    setIsDataFetched(false);
    try {
      const response = await fetch(`${API_URL}/api/Admin/Sickness/NewSickness?userEmail=${encodeURIComponent(account.username)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          leaveType: leaveType,
          NameOfSickEmployee: NameOfSickEmployee,
          LengthOfLeave: LengthOfLeave,
          StartDate: startDate,
          Comment: comment,
        }),
      })
      if (response.ok) {
        setReload(reload + 1);
      }
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    }
  }

  useEffect(() => {
    setIsDataFetched(false);
    const fetchData = fetch(`${API_URL}/api/Admin/Sickness/PageData?userEmail=${encodeURIComponent(account.username)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    Promise.all([fetchData])
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(([fetchedData]) => {
        setData(fetchedData)

        setSicknessLogData(fetchedData.SicknessLogData)
        setSicknessOverviewData(fetchedData.SicknessOverviewData)
        setIsDataFetched(true);

        setNameOfSickEmployee(data?.sick_employee_options[0])
        setLeaveType(leaveType);
        setLengthOfLeave(LengthOfLeave);
        setCurrentData(getCurrentDate());
        setComment("");
      });
  }, [reload]);

  // Then when the UPDATE button is clicked, you send selectedValues to the backend
  const handleFormSubmit = async (AdminComment) => {
    setIsDataFetched(false);
    setDisplayAdminForm(false)
    try {
      const response = await fetch(`${API_URL}/api/Admin/Sickness/SignOff?userEmail=${encodeURIComponent(account.username)}&LeaveID=${encodeURIComponent(formID)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          AdminComment: AdminComment
        }),
      })
      if (response.ok) {
        setReload(reload + 1);
      }
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    }
  };

  const handleSignOffClick = (id) => {
    setLoading(true)
    setFormID(id)

    const fetchData = fetch(`${API_URL}/api/Admin/Sickness/FormData?userEmail=${encodeURIComponent(account.username)}&LeaveID=${encodeURIComponent(id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    Promise.all([fetchData])
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(([fetchedData]) => {
        setFormData(fetchedData)
        setLoading(false)
        setDisplayAdminForm(true)
      });
  }

  useEffect(() => {
    console.log('loading: ', loading)
  }, [loading]);

  const [returnedId, setReturnedId] = useState(0);

  const handleSubmitClick = () => {
    handleSubmitReload();
    setDocUpload(false);
  };

  const handleSubmitReload = async () => {
    setIsDataFetched(false);
    console.log('DataTitle, selectedValues', selectedValues)
    try {
      const response = await fetch(`${API_URL}/api/Admin/Sickness/ReturningEmployee?userEmail=${encodeURIComponent(account.username)}&returnedId=${encodeURIComponent(returnedId)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserEmail: account.username,
          StartDate: startDate,
          end_time: ReturnDate,
        }),
      })
      if (response.ok) {
        setReload(reload + 1);
      }
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    }
  };

  const customStyles = {
    overlay: {
      zIndex: 10000,
      backgroundColor: 'transparent', // change this to transparent
    },
    content: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eaf2f9',
      width: '359px',
      height: '195px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
  };

  const [docUpload, setDocUpload] = useState(false);

  const handleReturnedClick = (id) => {
    const start = new Date(startDate.startDate);
    start.setHours(0, 0, 0, 0); // Normalize time to start of the day

    const targetArray = data?.OpenSicknessData.rows.find(row => row[0] === id);
    const dateString = targetArray[4];

    // 1. Parse the date string
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [day, monthString, yearSuffix] = dateString.split('-');
    const month = months.indexOf(monthString);
    const year = 2000 + parseInt(yearSuffix, 10); // assuming 21st century (i.e., '23' becomes 2023)

    const parsedDate = new Date(year, month, parseInt(day, 10));

    // 3. Calculate the difference in milliseconds from the 'startDate'
    const differenceInMilliseconds = start - parsedDate;

    // 4. Convert the difference to days
    const differenceInDays = Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    setDocUpload(true)
    setReturnedId(id)
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setDocUpload(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };


  return (
    <>
      {isDataFetched ? (
        <div className={stylies.mainContent}>
          <div className={stylies.topSectionDiv}>
            <div className={stylies.OpenSicknessSection}>
              <div className={stylies.title}>Open Sickness</div>
              <div className={stylies.OpenSicknessTable}>
                <Table data={data?.OpenSicknessData} indexPassed={true} handleButtonClick={handleReturnedClick} />
              </div>
            </div>

            <div className={stylies.LogEmployeeSicknessSection}>
              <div className={stylies.title}>Log Employee Sickness</div>
              <div className={stylies.LogEmployeeSicknessForm}>
                <div className={stylies.FormRowSection}>
                  <div className={stylies.FormTextAndInputSection}>
                    <div className={stylies.FormText}>Type of Sickness</div>
                    <div className={stylies.FormInput}>
                      <Dropdown options={leave_type_options} initialSelectedValue={leaveType} onValueChange={setLeaveType} options_width='120%' />
                    </div>
                  </div>
                  <div className={stylies.FormTextAndInputSection}>
                    <div className={stylies.FormText}>Employee Name</div>
                    <div className={stylies.FormInput}>
                      <Dropdown options={data?.sick_employee_options} initialSelectedValue={data?.sick_employee_options[0]} onValueChange={setNameOfSickEmployee} />
                    </div>
                  </div>
                </div>
                <div className={stylies.FormRowSection}>
                  <div className={stylies.FormTextAndInputSection}>
                    <div className={stylies.FormText}>Length of Leave</div>
                    <div className={stylies.FormInput}>
                      <Dropdown options={Length_of_Leave_options} initialSelectedValue={LengthOfLeave} onValueChange={setLengthOfLeave} />
                    </div>
                  </div>
                  <div className={stylies.FormTextAndInputSection}>
                    <div className={stylies.FormText}>Start Date</div>
                    <div className={stylies.FormInput}>
                      <DatePicker dataName={startDate} initialDate={currentData} onDatesChange={setStartDate} visableIcon={true} />
                      {/* <img className={stylies.clalanderIcon} style={{top: '328px', right: '51px' }} src={Calendar} alt='CalendarIcon' /> */}
                    </div>
                  </div>
                </div>
                <div className={stylies.bookingLeaveRowComment}>
                  <textarea className={isFocused ? stylies.bookingLeaveComment : stylies.nonBookingLeaveComment}
                    id='comment'
                    placeholder={isFocused ? '' : 'Comment...'}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}></textarea>
                </div>
                <div className={stylies.submitdiv}>
                  <button className={stylies.SubmitButton} onClick={handleSubmit}>SUBMIT</button>
                </div>
              </div>
            </div>
          </div>

          <div className={stylies.bottomSectionDiv}>
            <div className={stylies.SicknessOverviewSection}>
              <div className={stylies.title}>Sickness Overview</div>
              <div className={stylies.SicknessOverviewTable}>
                <Table data={SicknessOverviewData} />
              </div>
            </div>

            <div className={stylies.SicknessLogSection}>
              <div className={stylies.title}>Sickness Log</div>
              <div className={stylies.SicknessLogTable}>
                <Table data={SicknessLogData} indexPassed={true} handleButtonClick={handleSignOffClick} />
              </div>
            </div>
          </div>

          {loading ? // Here is the code for displaying error/success messages
            <div className={stylies.LoadOverlay}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={65} />
              </Box>
            </div>
            :
            displayAdminForm && <AdminForm data={formData} handleFormSubmit={handleFormSubmit} setDisplayAdminForm={setDisplayAdminForm} actionType={'SUBMIT'} />
          }

          {docUpload && <div className={stylies.backGround} onClick={handleClose} style={{ paddingBottom: '25px' }}>
            <div className={stylies.form} onClick={handleFormClick}>


              <div style={{ fontSize: 14, fontFamily: 'PT Sans', textAlign: 'center', marginTop: '29px', marginBottom: '20px' }}>Please confirm the employees return to work date</div>
              <div className={stylies.FormRowSection} style={{ alignItems: 'center', justifyContent: 'center' }}>
                <div className={stylies.FormTextAndInputSection} style={{ marginRight: '12px', marginLeft: '25px' }}>
                  <div className={stylies.FormText}>Return Time</div>
                  <div className={stylies.FormInput}>
                    <Dropdown options={Length_of_Leave_options} initialSelectedValue={ReturnDate} onValueChange={setReturnDate} />
                  </div>
                </div>
                <div className={stylies.FormTextAndInputSection} style={{ marginLeft: '12px', marginRight: '25px' }}>
                  <div className={stylies.FormInput} style={{ position: 'relative' }}>
                    <div className={stylies.FormText}>Return Date</div>
                    <DatePicker dataName={startDate} initialDate={currentData} onDatesChange={setStartDate} visableIcon={true} />
                    {/* <img className={stylies.clalanderIcon} src={Calendar} alt='CalendarIcon' /> */}
                  </div>
                </div>
              </div>
              {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <div className={stylies.FormText}>No. of Working Days Absent: <span style={{fontWeight: 'bold', color: 'black'}}>{calculation} Days</span></div>
                </div> */}
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
                <button className={buttonStyles.YesButtonComponentStyling} style={{ marginRight: '10px' }} onClick={handleSubmitClick}>SUBMIT</button>
                <button className={buttonStyles.NoButtonComponentStyling} onClick={handleClose} >CANCEL</button>
              </div>


            </div>
          </div>}

        </div>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center' }}>
          <CircularProgress size={65} />
        </Box>
      )}
    </>
  );
}

export default Sickness;