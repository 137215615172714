import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import AdminContext from '../../../Context/adminContext';

import NumberScroller from '../../../Components/NumberScroller/NumberScroller';
import DatePicker from '../../../Components/DatePicker/DatePicker';

import LeftPagination from '../../../assets/Left_Pagination_Arrow.svg';
import RightPagination from '../../../assets/Right_Pagination_Arrow.svg';
import PenIcon from '../../../assets/Edit.svg';

import buttonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css'
import './Timesheets.css';
import CommentBox from '../../../Components/CommentBox/CommentBox';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import { Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';


// Utility function to format a number in English (Great Britain) style.
// It formats a number with a specified number of decimal places.
function formatNumber(value) {
    // Using the Intl.NumberFormat constructor to format the value.
    // Setting the locale to 'en-GB' for UK-style formatting.
    // The options ensure that the formatted number has a minimum of 0 and a maximum of 2 decimal places.
    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value);
}

// The Timesheets component handles the display and manipulation of timesheet data.
// It fetches data, classifies rows, and handles user interactions like pagination and exporting data.
function Timesheets() {
    const { accounts } = useMsal();
    const account = accounts[0];
    const API_URL = process.env.REACT_APP_API_URL;
    const admin = useContext(AdminContext);

    const [rows, setRows] = useState([]);
    const [comments, setComments] = useState(new Map());

    // classifyRows function categorizes timesheet rows based on their completion date
    // relative to the given weekStart date.
    const classifyRows = (rowsToClassify, weekStart) => {
        // Validate input to ensure rowsToClassify is an array. Log an error and return empty array for invalid input.
        if (!Array.isArray(rowsToClassify)) {
            console.error('Invalid rows data:', rowsToClassify);
            return []; // Return an empty array or some default state
        }

        // Determine the start and end dates for the previous week based on weekStart.
        const previousWeekStart = new Date(weekStart);
        previousWeekStart.setDate(previousWeekStart.getDate() - 7); // Adjust to the previous Monday
        const previousWeekEnd = new Date(previousWeekStart);
        previousWeekEnd.setDate(previousWeekEnd.getDate() + 4); // Adjust to the previous Friday

        // Filter out rows based on criteria and categorize each row.
        return rowsToClassify
            .map(row => {
                const completedDate = new Date(row.completed_date);
                const isRecentlyCompleted = completedDate >= previousWeekStart && completedDate <= previousWeekEnd;

                // Check if the row qualifies as 'Recently Completed' and ensure 'date_added' is not "N/A".
                if (isRecentlyCompleted) {
                    if (row.date_added != "N/A") {
                        return null; // Exclude this row
                    }
                    return { ...row, section: 'Recently Completed' };
                } else if (!isRecentlyCompleted && row.section === "Complete") {
                    return { ...row, section: 'Completed Jobs' };
                }
                // Return row unchanged if it doesn't meet the criteria above
                return row;
            })
            .filter(row => row !== null); // Filter out any null rows
    };

    // fetchData asynchronously retrieves timesheet data for a given week.
    // It calculates the start and end dates for the week and fetches data accordingly.
    const fetchData = async () => {
        setLoading(true); // Indicate the start of data fetching process

        try {
            // Calculate the start and end dates of the current week
            const weekStart = new Date(currentWeekStart);
            const weekEnd = new Date(currentWeekStart);
            weekEnd.setDate(weekEnd.getDate() + 6);

            // Format dates to 'YYYY-MM-DD' format
            const startDate = weekStart.toISOString().split('T')[0];
            const endDate = weekEnd.toISOString().split('T')[0];

            // Make a GET request to the API to fetch timesheet data for the specified date range
            const response = await axios.get(
                `${API_URL}/api/timesheets/getSheet?UID=${account.username}&startDate=${startDate}&endDate=${endDate}`,
                { headers: { 'Content-Type': 'application/json' } }
            );

            // Return the fetched data if it's an array, or an empty array if not.
            const data = response.data;
            return Array.isArray(data) ? data : [];
        } catch (error) {
            // Log error and return an empty array in case of an exception.
            console.error('Error fetching data:', error);
            return [];
        } finally {
            setLoading(false);  // Indicate the end of the data fetching process
        }
    };

    const saveCommentToAPI = async (commentData) => {
        try {
            // Add UserEmail to commentData
            const updatedCommentData = {
                ...commentData,
                UserEmail: account.username
            };

            const response = await axios.post(
                `${API_URL}/api/timesheets/saveComment`,
                updatedCommentData,
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (response.status === 200) {
                console.log('Comment saved successfully');
            }
        } catch (error) {
            console.error('Error saving comment:', error);
        }
    };

    // exportTimesheets function handles the downloading of timesheets as an Excel file.
    // It makes an API call and triggers a file download in the browser.
    const exportTimesheets = async () => {
        try {
            // Make an API call to request the timesheet data in CSV format
            const response = await axios.get(`${API_URL}/api/export/timesheets`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob(
                [response.data],
                { type: 'text/csv' }  // Updated MIME type for CSV files
            );

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Timesheet Dashboard.csv'); // Updated default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            setLoading(false);
        } catch (error) {
            // Log error if the export process fails
            console.error('Error during export:', error);
        }
    };

    // addCategory function adds a new category to the timesheet.
    // It sends a POST request with the new category data
    const addCategory = async (addInputValue) => {
        try {
            // Convert currentWeekStart to 'YYYY-MM-DD' format
            const weekStartDate = currentWeekStart.toISOString().split('T')[0];

            // Make a POST request to the API to add a new category with the provided inputValue
            const response = await fetch(
                `${API_URL}/api/timesheets/addCategory`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        UserEmail: account.username,
                        CategoryName: addInputValue,
                        DateAdded: weekStartDate,
                    }),
                }
            );

            // Process the response and update the UI accordingly.
            if (response.ok) {
                const data = await response.json();
                setReload(reload + 1); // Trigger a re-fetch of data
                setApiResponse(data.message); // Display response message
            } else {
                // Handle backend errors by displaying an error message
                setApiResponse('Error adding category (backend ran into a logical issue, please let a DevOps engineer know)');
            }

        } catch (error) {
            // Log error and throw an exception for higher-level error handling
            console.error(error);
            throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
            setIsResponseShown(true); // Display the response message
        }
    };

    // saveTimesheet function saves the current state of timesheets.
    // It allows for posting the timesheets if the 'posted' flag is true.
    const saveTimesheet = async (rows, posted = false) => {
        try {
            // Make a POST request to the API to save the current state of the timesheets.
            // The 'posted' flag indicates whether the timesheets should be marked as posted.
            const response = await fetch(
                `${API_URL}/api/timesheets/saveTimesheet`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        UserEmail: account.username,
                        Data: rows,
                        Posted: posted,
                    }),
                }
            );

            // Handle the response from the API
            if (response.ok) {
                if (posted) {
                    const data = await response.json();
                    setApiResponse(data.message); // Display success message if posted
                }
            } else {
                // Display error message for backend logical errors
                setApiResponse('Error saving timesheets (backend ran into a logical issue, please let a DevOps engineer know)');
            }

        } catch (error) {
            // Log error and throw an exception for higher-level error handling
            console.error(error);
            throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
            // Update the UI based on the 'posted' status
            if (posted) {
                setReload(reload + 1); // Re-fetch data if timesheets were posted
                setIsResponseShown(true); // Display the response message
            }
        }
    };

    const getCurrentDateInFormat = () => {
        const currentDate = new Date();
        const formattedDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString();
        return formattedDate;
    };

    // State variables for managing various aspects of the component
    const [loading, setLoading] = useState(true); // Tracks loading status
    const [reload, setReload] = useState(0); // Used to trigger data re-fetch

    const [isResponseShown, setIsResponseShown] = useState(false); // Controls visibility of response message
    const [apiResponse, setApiResponse] = useState(null); // Stores API response messages

    const [commentModal, setCommentModalOpen] = useState(false); // Toggles comment modal visibility
    const [AddModal, setAddModalOpen] = useState(false); // Toggles add modal visibility

    let updatedRows = []; // Temporary storage for rows that have been edited
    const [expandedRowIds, setExpandedRowIds] = useState([]); // Stores IDs of expanded rows
    const [currentIndices, setCurrentIndices] = useState({
        rowIndex: null,
        rowId: null,
        expandableRowIndex: null,
        dayIndex: null,
        date: null,
        locked: null,
        category_id: null,
        link_id: null,
        level: null,
        client: null,
    });
    const [currentComment, setCurrentComment] = useState(null); // Stores current comment in modal
    const [currentLockedStatus, setCurrentLockedStatus] = useState(false); // Lock status for comments

    const [inputValue, setinputValue] = useState(null); // Stores user input for searches
    const [addInputValue, setAddInputValue] = useState(null); // Stores user input for searches (Within add modal)
    const [searchTerm, setsearchTerm] = useState(null); // Stores the current search term
    const [searchOptions, setsearchOptions] = useState(null); // Stores search options from API
    let timerId = null; // Timer ID for debouncing input

    const [totals, setColumnTotals] = useState(Array(7).fill(0)); // Stores total values for each day of the week
    const [maxTotal, setMaxColumnTotal] = useState(0); // Stores the maximum total value
    const [unpostedDays, setUnpostedDays] = useState(new Array(7).fill(false));
    const [startDate, setStartDate] = useState({ startDate: getCurrentDateInFormat() });


    const [currentWeekStart, setCurrentWeekStart] = useState(getCurrentMonday()); // Stores the start date of the current week

    function isDateWithinPreviousWeek(completedDateString, currentWeekStart) {
        // Determine the start date of the previous week
        const previousWeekStart = new Date(currentWeekStart);
        previousWeekStart.setDate(previousWeekStart.getDate() - 7);

        // Determine the end date of the previous week
        const previousWeekEnd = new Date(currentWeekStart);
        previousWeekEnd.setDate(previousWeekEnd.getDate() - 1);

        // Create a date object from the completedDateString
        const completedDate = new Date(completedDateString);

        // Check if the completed date is within the range of the previous week
        return completedDate >= previousWeekStart && completedDate <= previousWeekEnd;
    }


    const fetchSearchOptions = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/timesheets/addSearchOptions`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = response.data.data;
            setsearchOptions(data);
        } catch (error) {
            console.error(error);
            throw new Error(`HTTP error! status: ${error.response.status}`);
        }
    };

    const handleDateChange = (dateObject) => {
        // Check if dateObject has a valid startDate
        if (dateObject && dateObject.startDate && !isNaN(Date.parse(dateObject.startDate))) {
            // Convert the selected start date to the beginning of its week
            const newWeekStart = new Date(dateObject.startDate);
            newWeekStart.setDate(newWeekStart.getDate() - newWeekStart.getDay() + 1);

            console.log("New Week Start:", newWeekStart);
            setCurrentWeekStart(newWeekStart);
        } else {
            console.error('Invalid date object:', dateObject);
        }
    };

    // Utility function to get the current Monday date
    function getCurrentMonday() {
        const today = new Date();
        const day = today.getDay();
        const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust to the most recent Monday
        return new Date(today.setDate(diff));
    }

    const getCurrentWeekDates = (currentWeekStart) => {
        const dates = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(Date.UTC(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate() + i));
            dates.push(date.toISOString().split('T')[0]);  // 'YYYY-MM-DD'
        }
        return dates;
    };

    // Pagination and date-related functions
    const goPreviousWeek = () => adjustWeekBy(-7);
    const goNextWeek = () => adjustWeekBy(7);

    function adjustWeekBy(days) {
        setCurrentWeekStart(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        });
    }

    function hasDatePassed(dateStr) {
        const today = new Date();
        const completedDate = new Date(dateStr);

        // Set the time to the very end of the day for comparison
        completedDate.setHours(23, 59, 59, 999);

        return completedDate < today;
    }

    const resetToCurrentWeek = () => setCurrentWeekStart(getCurrentMonday());

    const isDateWithinCurrentWeek = (dateStr, currentWeekStart) => {
        const date = new Date(dateStr);
        date.setHours(0, 0, 0, 0);

        const weekStart = new Date(currentWeekStart);
        weekStart.setHours(0, 0, 0, 0);

        const weekEnd = new Date(currentWeekStart);
        weekEnd.setHours(0, 0, 0, 0);
        weekEnd.setDate(weekEnd.getDate() + 6);

        return date >= weekStart && date <= weekEnd;
    };

    // Handles changes in number inputs
    const handleNumberChange = (newVal, parentRowId, indexInExpandedSet, date) => {

        // Clear the existing timer if any
        if (timerId !== null) {
            clearTimeout(timerId);
        }

        // Start a new timer
        timerId = setTimeout(() => {
            setRows(prevRows => {
                const updatedRowsInState = [...prevRows];
                const rowIndex = updatedRowsInState.findIndex(row => row.id === parentRowId);

                if (rowIndex === -1) {
                    console.error("Row not found");
                    return prevRows;
                }

                const expandableRow = updatedRowsInState[rowIndex].expandableRows[indexInExpandedSet];

                if (!expandableRow) {
                    console.error("ExpandableRow not found");
                    return prevRows;
                }

                const dayIndex = expandableRow.days.findIndex(([, , dayDate]) => dayDate === date);

                // If the day doesn't exist, create it with five elements
                if (dayIndex === -1) {
                    expandableRow.days.push([newVal, false, date, null, 1]);
                } else {
                    // Ensure the day array has five elements
                    while (expandableRow.days[dayIndex].length < 5) {
                        expandableRow.days[dayIndex].push(null);
                    }
                    expandableRow.days[dayIndex][0] = newVal;
                }

                return updatedRowsInState;
            });

            // Find if this parentRowId already exists in updatedRows
            const rowIndex = updatedRows.findIndex(row => row.id === parentRowId);

            if (rowIndex === -1) {
                // Clone the row from rows state
                const originalRow = rows.find(row => row.id === parentRowId);
                const clonedRow = JSON.parse(JSON.stringify(originalRow));

                // Update the day in the cloned row
                const expandableRow = clonedRow.expandableRows[indexInExpandedSet];
                const dayIndex = expandableRow.days.findIndex(([, , dayDate]) => dayDate === date);

                if (dayIndex === -1) {
                    expandableRow.days.push([newVal, false, date, null, 1]);
                } else {
                    expandableRow.days[dayIndex][0] = newVal;
                }

                // Add the cloned row to updatedRows
                updatedRows.push(clonedRow);
            } else {
                // Update the day in the existing updated row
                const expandableRow = updatedRows[rowIndex].expandableRows[indexInExpandedSet];
                const dayIndex = expandableRow.days.findIndex(([, , dayDate]) => dayDate === date);

                if (dayIndex === -1) {
                    expandableRow.days.push([newVal, false, date, null, 1]);
                } else {
                    expandableRow.days[dayIndex][0] = newVal;
                }
            }

            console.log(updatedRows)

            // Call API function here
            saveTimesheet(updatedRows, false);
        }, 500);

    };


    // Generates table headers based on the current week
    const generateTableHeaders = () => {
        const headers = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, i) => {
            const date = new Date(currentWeekStart);
            date.setDate(date.getDate() + i);
            return `${day} ${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
        });
        return headers;
    };

    const headers = generateTableHeaders(); // Generates and stores table headers

    // Event handler to toggle the expanded state of a row
    const toggleRows = (id) => {
        if (expandedRowIds.includes(id)) {
            // If the row is already expanded, remove it from the array
            setExpandedRowIds(prevIds => prevIds.filter(rowId => rowId !== id));
        } else {
            // Otherwise, add it to the array
            setExpandedRowIds(prevIds => [...prevIds, id]);
        }
    };

    const calculateRowTotal = (daysArray) => {
        return daysArray ? daysArray.reduce((acc, curr) => acc + curr, 0) : 0;
    };

    // useEffect hook for fetching data and updating rows based on component mount or reload changes
    useEffect(() => {
        const fetchDataAndClassify = async () => {
            const fetchedRows = await fetchData();
            const classifiedRows = classifyRows(fetchedRows, currentWeekStart);
            setRows(classifiedRows);
            // Fetch search options
            fetchSearchOptions();
        };

        fetchDataAndClassify();
    }, [reload, currentWeekStart]); // Ensure this effect runs on mount and whenever 'reload' or 'currentWeekStart' changes

    // Calculates totals based on current rows and week start
    useEffect(() => {
        let newTotals = Array(7).fill(0);
        let newMaxTotal = 0;
        let localUnpostedDays = new Array(7).fill(false); // Define a local version to track unposted days

        const currentWeekDates = getCurrentWeekDates(currentWeekStart);

        // Create a mapping of date to index
        const dateToIndexMap = {};
        currentWeekDates.forEach((date, index) => {
            dateToIndexMap[date] = index;
        });

        // This logic correctly identifies unposted, significant time and accumulates time for each day across all sections
        const accumulateTotals = (row) => {
            if (row.days) {
                row.days.forEach(([dayValue, posted, dayDate]) => {
                    const index = dateToIndexMap[dayDate];
                    if (currentWeekDates.includes(dayDate)) {
                        if (dayValue !== 0 && !posted) {
                            localUnpostedDays[index] = true; // Mark as unposted
                        }
                        newTotals[index] += dayValue;
                        newMaxTotal += dayValue;
                    }
                });
            }
        };

        // Sort the rows by id in descending order
        rows.sort((a, b) => b.id - a.id);

        // Calculate totals for each parent row for all time submitted within the subcategories and track unposted days for each parent and expandable row
        rows.forEach(row => {
            accumulateTotals(row);

            if (row.expandableRows) {
                row.expandableRows.forEach(expandRow => {
                    accumulateTotals(expandRow);
                });
            }
        });

        setColumnTotals(newTotals);
        setMaxColumnTotal(newMaxTotal);
        setUnpostedDays(localUnpostedDays);
    }, [rows, currentWeekStart]);

    // Update Sections to be Recently Completed or Completed Jobs
    useEffect(() => {
        if (rows && rows.length > 0) {
            const updatedRows = rows.map(row => {
                if (!row) {
                    console.error("Row is undefined or null");
                    return row;
                }

                const previousWeekStart = new Date(currentWeekStart);
                previousWeekStart.setDate(previousWeekStart.getDate() - 7); // Go back to Monday of the previous week
                const previousWeekEnd = new Date(previousWeekStart);
                previousWeekEnd.setDate(previousWeekEnd.getDate() + 4); // Set to Friday of the previous week

                const completedDate = new Date(row.completed_date);
                const isRecentlyCompleted = completedDate >= previousWeekStart && completedDate <= previousWeekEnd;
                const isCurrentWeek = currentWeekStart <= new Date() && new Date() <= previousWeekEnd; // Check if we're in the current week (Monday to Friday)

                // If the job is completed within the last week and we are in the current week, show it as recently completed
                // Also check if the job is posted, if required
                if (isRecentlyCompleted && isCurrentWeek && (row.posted || typeof row.posted === 'undefined')) {
                    return { ...row, section: 'Recently Completed' };
                } else if (!isRecentlyCompleted && row.section === "Complete") {
                    return { ...row, section: 'Completed Jobs' };
                } else {
                    return row; // Keep the current section if none of the above conditions meet
                }
            });

            if (JSON.stringify(rows) !== JSON.stringify(updatedRows)) {
                setRows(updatedRows);
            }
        }
    }, [rows, currentWeekStart]);



    // The renderRow function renders a single row in the timesheet table.
    // It handles different styles and interactions based on row type (parent or child).
    const renderRow = (row, isExpanded, isParent, parentRowId = null, indexInExpandedSet) => {
        const backgroundColor = indexInExpandedSet % 2 === 0 ? '#E8F0F980' : 'white';
        const cellClass = isExpanded ? 'no-padding' : '';  // Add a 'no-padding' class if row is expanded

        const rowId = isParent ? row.id : parentRowId;  // Use parent ID for expandable rows, row ID otherwise

        // Generate an array of dates for the current week
        const currentWeekDates = getCurrentWeekDates(currentWeekStart);

        return (
            <tr style={{ backgroundColor: isExpanded ? backgroundColor : 'inherit', cursor: isParent ? 'pointer' : 'default' }}
                key={row.id}
                onClick={isParent ? () => toggleRows(row.id) : null}
            >
                {/* Project Number */}
                <td>
                    <div className="cell-wrapper">
                        {isParent && (
                            <div className={`expanded-arrow ${isExpanded ? 'rotated' : ''}`} />
                        )}
                        {/* Wrap the project number in an anchor or Link tag */}
                        <Link to={`/projects/ProjectProfile#${row.id}`} className='cell-text no-style-link' onClick={(e) => e.stopPropagation()}>
                            {row.id > 100000 ? '0000' : row.id}
                        </Link>
                    </div>
                </td>

                {/* Project Address / Category Name */}
                <td className={isExpanded && !isParent ? 'expanded-address' : ''}>
                    {isParent ? row.category : row.subcategory}
                </td>

                {/* Conditional rendering based on isParent */}
                {isParent ? (
                    // Render totals for parent rows
                    currentWeekDates.map((date, index) => {
                        // Initialize total for this day and flags to check if all are posted and if there's significant drafted time
                        let dayTotal = 0;
                        let allPosted = true;
                        let dataExists = false; // Flag to check if any significant data exists for this day

                        // Loop through each child row to accumulate the total for this day
                        row.expandableRows.forEach(childRow => {
                            const foundDay = childRow.days ? childRow.days.find(([value, posted, dayDate]) => {
                                if (dayDate === date && value !== '' && value !== null && value !== 0) {
                                    dataExists = true; // Set the flag if any significant data exists

                                    // If the day is not posted and the value is significant, set allPosted to false
                                    if (!posted) allPosted = false;

                                    return true;
                                }
                                return false;
                            }) : null;
                            if (foundDay) {
                                dayTotal += foundDay[0]; // Assuming the day value is at index 0
                            }
                        });

                        // Determine the text color based on the allPosted flag and whether it's a weekend day
                        const isWeekend = index >= currentWeekDates.length - 2;
                        const textColor = isWeekend ? '#c4c4c4' : allPosted ? 'black' : 'red';

                        return (
                            // Column Totals (Mon-Sun; 7 Repetitions)
                            <td key={index} style={{ color: textColor }}>
                                {/* Only display the total if there's posted data for this day */}
                                {dataExists ? formatNumber(dayTotal) : ''}
                            </td>
                        );
                    })
                ) : (
                    // Render NumberScrollers for non-parent rows
                    currentWeekDates.map((date, index) => {
                        console.log(currentWeekDates)
                        let WeekType
                        const weekendDays = [5, 6];
                        const key = `${parentRowId || row.id}-${indexInExpandedSet}-${index}`;

                        if (weekendDays.includes(Number(index))) {
                            WeekType = "Weekend";
                        } else {
                            WeekType = "Weekday";
                        }

                        const foundDay = row.days ? row.days.find(([, , dayDate]) => dayDate === date) : null;
                        const [dayValue, locked] = foundDay ? foundDay : [0, false];

                        // Only show the NumberScroller if the date matches or defaults to 0
                        const valueToShow = foundDay ? dayValue : 0;

                        // Determine the color based on the posted status
                        const color = row.posted ? 'black' : 'red';

                        return (
                            <td className={cellClass} key={index}>
                                <div className="penIconContainer">
                                    <div className='numberScroller'>
                                        <NumberScroller
                                            initialData={valueToShow}
                                            locked={locked}
                                            onValueChange={(newVal) => handleNumberChange(newVal, rowId, indexInExpandedSet, date, row.subcategory)}
                                            type={WeekType}
                                            color={color}
                                        />
                                    </div>
                                    {locked ? (
                                        foundDay && foundDay[3] && (foundDay[3].trim() !== "None" && foundDay[3].trim() !== '') ? (
                                            <div className="penIcon" onClick={() => openCommentModal(foundDay[3], rowId, indexInExpandedSet, index, date, true, row.link_id, row.category_id)}>
                                                <img src={PenIcon} style={{ filter: 'brightness(0)' }} alt="Edit" />
                                            </div>
                                        ) : (
                                            <div className="penIcon">
                                                <div style={{ width: '20px', height: '20px' }} />
                                            </div>
                                        )
                                    ) : (
                                        foundDay && foundDay[3] && (foundDay[3].trim() !== "None" && foundDay[3].trim() !== '') ? (
                                            <div className="penIcon" onClick={() => openCommentModal(foundDay[3], rowId, indexInExpandedSet, index, date, locked, row.link_id, row.subcategory)}>
                                                <img src={PenIcon} style={{ filter: 'brightness(0)' }} alt="Edit" />
                                            </div>
                                        ) : (
                                            <div className="penIcon" onClick={() => openCommentModal(foundDay && foundDay[3] && foundDay[3].trim() !== "None" ? foundDay[3] : '', rowId, indexInExpandedSet, index, date, locked, row.link_id, row.subcategory)}>
                                                <img src={PenIcon} alt="Edit" />
                                            </div>
                                        )

                                    )}
                                </div>
                            </td>
                        );
                    })
                )}

                {/* Total */}
                <td style={{ alignItems: 'center' }}>
                    <div className='Total'>
                        {isParent ? (
                            formatNumber(row.expandableRows.reduce((total, childRow) => {
                                return total + (childRow.days ?
                                    childRow.days
                                        .filter(([, , dayDate]) => currentWeekDates.includes(dayDate))
                                        .reduce((dayTotal, [value]) => dayTotal + value, 0)
                                    : 0);
                            }, 0))
                        ) : (
                            // Existing code for non-parent rows
                            row.days ? formatNumber(calculateRowTotal(row.days
                                .filter(([, , date]) => currentWeekDates.includes(date))
                                .map(([dayValue]) => dayValue))) : ''
                        )}
                    </div>

                </td>

                {/* Tags */}
                <td>
                    {isParent && (
                        row.tags && (Array.isArray(row.tags) ? row.tags : row.tags.split(', ')).length > 0 ? (
                            <div style={{ display: 'flex' }}>
                                {(Array.isArray(row.tags) ? row.tags : row.tags.split(', ')).map((tag, index) => {
                                    const abbreviateTag = tag.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
                                    return (
                                        <div className="Tag" key={index} style={{ marginRight: '3px' }}>
                                            {abbreviateTag}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div style={{ height: '25px' }}></div>
                        )
                    )}
                </td>

                {/* Client */}
                <td>
                    {row.client}
                    {/* <Link to={`/clients/ClientProfile#${row.id}`} className='cell-text no-style-link' onClick={(e) => e.stopPropagation()}>
                        {row.client}
                    </Link> */}
                </td>
            </tr>
        );
    };

    // Define the order array
    const sectionOrder = ['Non Projects', 'Internal Projects', 'Live Jobs', 'On Hold Jobs', 'Dormant Jobs', 'Completed Jobs', 'Recently Completed'];

    // Obtain all unique section names and ensure 'Recently Completed' is included
    const uniqueSections = [...new Set(rows.map(row => row.section))];
    if (!uniqueSections.includes('Recently Completed')) {
        uniqueSections.push('Recently Completed'); // Add 'Recently Completed' if not already present
    }

    // Sort uniqueSections based on the order array
    const sortedUniqueSections = uniqueSections.sort((a, b) => sectionOrder.indexOf(a) - sectionOrder.indexOf(b));

    const handleSearch = (event) => {
        setinputValue(event.target.value);
    };

    const handleAddSearch = (event) => {
        setAddInputValue(event.target.value);
    };

    const handleSearchSubmit = () => {
        setsearchTerm(inputValue.toLowerCase());
    };

    const handleAddSearchSubmit = () => {
        setsearchTerm(addInputValue.toLowerCase());
    };

    // Function to filter rows based on the search term
    const filterRowsBySearchTerm = (rows) => {

        let SearchResult = inputValue ? inputValue.toLowerCase() : '';

        if (!SearchResult) return rows;

        return rows.filter(row => {
            // Check if 'category'(Project Name), 'client', or 'id'(Project Number) exist and are not undefined before calling toLowerCase
            const categoryMatch = row.category && row.category.toLowerCase().includes(SearchResult);
            const clientMatch = row.client && row.client.toLowerCase().includes(SearchResult);
            const idMatch = String(row.id) && String(row.id).includes(SearchResult);

            // Modify this logic based on search requirements
            return categoryMatch || clientMatch || idMatch;
        });
    };

    const handleSearchEnterKey = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleAddSearchEnterKey = (event) => {
        if (event.key === 'Enter') {
            handleAddSearchSubmit();
        }
    };

    const openCommentModal = (
        comment,
        rowId,
        expandableRowIndex,
        dayIndex,
        date,
        locked = false,
        link_id,
        subcategory
    ) => {
        // Find the row to get category_id
        const row = rows.find(row => row.id === rowId);

        // Find the index of the row with the given ID
        const rowIndex = rows.findIndex(row => row.id === rowId);

        const category_id = row?.category_id;
        const level = row?.level;
        const client = row?.client;

        setCurrentLockedStatus(locked);
        setCurrentComment(comment);
        setCurrentIndices({
            rowIndex,
            rowId,
            expandableRowIndex,
            dayIndex,
            date,
            locked,
            category_id,
            link_id,
            level,
            client,
        });
        setCommentModalOpen(true);
    };

    const closeCommentModal = () => {
        setCommentModalOpen(false);
    };

    const openAddModal = () => {
        setAddInputValue(null);
        setAddModalOpen(true);
    }

    const closeAddModal = () => {
        setAddModalOpen(false);
    }

    const handleFormClick = (e) => {
        e.stopPropagation();  // Stops the click event from bubbling up to the parent div
    };

    const handleSubmitClick = () => {
        const { rowIndex, rowId, expandableRowIndex, dayIndex, date, locked, category_id, link_id, level, client } = currentIndices;

        // Prepare comment data for the API
        const commentData = {
            date,
            comment: currentComment,
            category_id,
            link_id,
            level,
            client
        };

        setRows(prevRows => {
            const updatedRows = [...prevRows];

            if (updatedRows[rowIndex]) {
                const expandableRow = updatedRows[rowIndex].expandableRows[expandableRowIndex];

                // Find the index in days that matches the date
                const dayIndex = expandableRow.days.findIndex(day => day[2] === date);

                if (dayIndex !== -1) {
                    // Update the comment at the found index
                    expandableRow.days[dayIndex][3] = currentComment;

                } else {
                    // Create default data entry with comment 
                    expandableRow.days.push([0, false, date, currentComment]);
                }
                return updatedRows;
            } else {
                console.error('Row index out of bounds:', rowIndex);  // Debug log
                return prevRows;
            }
        });

        // Save the comment
        saveCommentToAPI(commentData);

        // Close the modal
        setCommentModalOpen(false);
    };

    const handleAddSubmit = () => {
        addCategory(addInputValue);
        closeAddModal();
    }

    const handlePostAllClick = () => {
        saveTimesheet([], true);
    };

    const handleExportClick = () => {
        setLoading(true);
        exportTimesheets();
    }

    return (
        // JSX for rendering the Timesheets component
        // Includes logic for modals, search functionality, and table rendering
        <div className='TimesheetsMainContent'>
            <div className='TimesheetsTitleContainer'>
                <div className='TimesheetsTitle'>
                    Time Sheets
                </div>

                <div className='TimesheetButtons'>
                    {admin && <div className='ExportBtn' onClick={handleExportClick}>
                        EXPORT
                    </div>}

                    <div className='PostAllBtn' onClick={handlePostAllClick}>
                        POST ALL
                    </div>

                    <div className='AddBtn' onClick={openAddModal}>
                        ADD
                    </div>
                </div>
            </div>


            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100% - 20%)' }}>
                    <CircularProgress size={65} />
                </Box>
            ) : (
                <>
                    <div className='StickyHeader'>
                        <div className='TimesheetTotals'>
                            <table className='TimesheetsTable'>
                                <tbody>
                                    <tr>
                                        <td className='EmptyCell'></td> {/* Empty cell */}
                                        <td className='TotalTxt'>TOTAL</td>
                                        {totals.map((total, index) => {
                                            const isWeekend = index >= totals.length - 2;
                                            const textColor = isWeekend ? '#c4c4c4' : unpostedDays[index] ? 'red' : 'black';

                                            return (
                                                <td key={index} style={{ color: textColor }} className='TotalDay'>
                                                    {total !== 0 ? formatNumber(total) : ''}
                                                </td>
                                            );
                                        })}
                                        <td className={
                                            maxTotal < 40 ? 'MaxTotal red' :
                                                maxTotal === 40 ? 'MaxTotal blue' : 'MaxTotal green'
                                        }>
                                            {formatNumber(maxTotal)}
                                        </td>
                                        <td className='LastEmptyCell'></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='TimesheetPagination'>
                            <div className='LeftArrow' style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={goPreviousWeek}>
                                <img src={LeftPagination} alt='LeftArrow' />
                            </div>

                            <div className='PaginationDisplay' onClick={resetToCurrentWeek}>
                                THIS WEEK
                            </div>

                            <div className='RightArrow' style={{ cursor: 'pointer' }} onClick={goNextWeek}>
                                <img src={RightPagination} alt='RightArrow' />
                            </div>

                            <div style={{ marginInline: '25px', minWidth: '38px', maxWidth: '38px' }}>
                                <DatePicker
                                    dataName={startDate}
                                    initialDate={currentWeekStart instanceof Date ? currentWeekStart.toISOString().split('T')[0] : ''}
                                    onDatesChange={(newDate) => handleDateChange(newDate)}
                                    visableIcon={true}
                                    mondayOnly={true}
                                    borderThickness='2px'
                                    inputHeight='25px'
                                />
                            </div>
                        </div>

                        <div className='SearchContainer'>
                            <SearchBar searchWidth={'220px'} value={inputValue} onChange={handleSearch} onKeyDown={handleSearchEnterKey} disabled={loading} onClick={handleSearchSubmit} />
                        </div>
                    </div>

                    <div className='TimesheetsScrollableArea'>
                        {sortedUniqueSections.map(section => {
                            // Pre-filter the rows for this section based on date_added and currentWeekStart
                            // Use 'let' instead of 'const' for rowsForThisSection
                            let rowsForThisSection = rows.filter(row => row.section === section);

                            // Apply search filter
                            rowsForThisSection = filterRowsBySearchTerm(rowsForThisSection);

                            const currentWeekDates = getCurrentWeekDates(currentWeekStart);

                            let filteredRows;
                            // If the section is 'Recently Completed', don't filter rows, just assign an empty array.
                            if (section === 'Recently Completed') {
                                // Check if there are any rows for 'Recently Completed'
                                const rowsForRecentlyCompleted = rows.filter(row => row.section === 'Recently Completed');
                                if (rowsForRecentlyCompleted.length > 0) {
                                    // Use the rows if they exist
                                    filteredRows = rowsForRecentlyCompleted;
                                } else {
                                    // Otherwise, use an empty array to ensure the section is rendered
                                    filteredRows = [];
                                }
                            } else {
                                // Existing logic for filtering rows for other sections

                                if (section === 'Completed Jobs') {
                                    filteredRows = rowsForThisSection.filter(row => {
                                        // Determine if the job's completed_date is within the next 7 days and not past.
                                        const isWithinNext7Days = row.completed_date !== 'N/A' && isDateWithinPreviousWeek(row.completed_date) && !hasDatePassed(row.completed_date);

                                        // Determine if the job has an added_date within the current week.
                                        const isAddedDateWithinCurrentWeek = row.date_added !== 'N/A' && isDateWithinCurrentWeek(row.date_added, currentWeekStart);

                                        // Determine if the job has data for any day in the current week.
                                        const hasDataForCurrentWeek = row.expandableRows.some(expandRow =>
                                            expandRow.days && expandRow.days.some(([, , date]) => currentWeekDates.includes(date))
                                        );

                                        // The row should be displayed if any of these conditions are true.
                                        return isAddedDateWithinCurrentWeek || isWithinNext7Days || hasDataForCurrentWeek;
                                    });
                                } else if (section == 'Internal Projects') {
                                    filteredRows = rowsForThisSection.filter(row => {
                                        // Check if the row has data for any day in the current week.
                                        const hasCurrentWeekDaysData = row.expandableRows.some(expandRow =>
                                            expandRow.days && expandRow.days.some(([, , date]) => currentWeekDates.includes(date))
                                        );

                                        // Determine if the job has an added_date within the current week.
                                        const isAddedDateWithinCurrentWeek = row.date_added !== 'N/A' && isDateWithinCurrentWeek(row.date_added, currentWeekStart);

                                        // Include rows that don't have an added_date (assuming they are live jobs)
                                        // or have current week days data.
                                        return typeof isAddedDateWithinCurrentWeek || row.date_added === 'undefined' || row.date_added === 'N/A' || hasCurrentWeekDaysData;
                                    });
                                } else if (section != 'Internal Projects' && section != 'Completed Jobs' && section != 'Non Project' && section != 'Recently Completed') {
                                    filteredRows = rowsForThisSection.filter(row => {
                                        const hasCurrentWeekDaysData = row.expandableRows.some(expandRow =>
                                            expandRow.days && expandRow.days.some(([, , date]) => currentWeekDates.includes(date))
                                        );

                                        const isAddedDateWithinCurrentWeek = row.date_added !== 'N/A' && isDateWithinCurrentWeek(row.date_added, currentWeekStart);

                                        // Include rows that have current week days data or whose added date is within the current week.
                                        return (hasCurrentWeekDaysData || row.date_added === 'undefined' || row.date_added === 'N/A' || isAddedDateWithinCurrentWeek);
                                    });
                                } else {
                                    // For 'Non Project' section, include all rows without filtering.
                                    filteredRows = rowsForThisSection;
                                }

                                // If no rows should be displayed for this section (and it's not 'Non Project'), skip rendering the section.
                                if (filteredRows.length === 0 && section !== 'Non Project') {
                                    return null
                                }
                            }

                            // Always render the 'Recently Completed' section, even if it's empty
                            if (section === 'Recently Completed' || filteredRows.length > 0) {
                                return (
                                    <div className='Section' key={section}>
                                        <div className='TimesheetsSubTitle'>
                                            {section}
                                        </div>
                                        <div className='TableLayout'>
                                            <table className='TimesheetsTable'>
                                                <thead>
                                                    <tr>
                                                        <th className="jobNo">Job No</th>
                                                        <th className="address">Project Address</th>
                                                        {headers.map((header, index) => (
                                                            <th key={index} className="day">
                                                                <div className="dayWrapper">
                                                                    <div className="dayName">{header.split(' ')[0]}</div>
                                                                    <div className="dayDate">{header.split(' ').slice(1).join(' ')}</div>
                                                                </div>
                                                            </th>
                                                        ))}
                                                        <th className="day">Total</th>
                                                        <th className="tags">Tags</th>
                                                        <th className="client">Client</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredRows.length === 0 ?
                                                            (<td colSpan={12} className="tdNoInfo">No hours allocated.</td>)
                                                            :
                                                            filteredRows.map(row => {
                                                                // Determine if row is expanded
                                                                const isExpanded = expandedRowIds.includes(row.id);
                                                                // Determine if row is a parent row
                                                                const isParent = row.hasOwnProperty('expandableRows');
                                                                return (
                                                                    <>
                                                                        {renderRow(row, isExpanded, isParent)}
                                                                        {isExpanded && row.expandableRows.map((expandRow, index) =>
                                                                            renderRow(expandRow, true, false, row.id, index))}
                                                                    </>
                                                                );
                                                            })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </>
            )}

            {/* Response Modal */}
            {isResponseShown && (
                <div className='ProjectProfileOverlay'>
                    <div className='ProjectProfileResponseBox'>
                        <div
                            style={{
                                fontSize: 16,
                                fontFamily: 'PT Sans',
                                textAlign: 'center',
                                margin: '20px',
                                marginLeft: '25px',
                                marginRight: '25px'
                            }}

                            dangerouslySetInnerHTML={{ __html: apiResponse }}
                        >

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div
                                className='ProjectProfileButton'
                                onClick={() => setIsResponseShown(false)}
                            >
                                OK
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Modal */}
            {AddModal && <div className='backGround' onClick={closeAddModal}>
                <div className='form' style={{ width: '457px', height: '156px' }} onClick={handleFormClick}>

                    <div style={{
                        color: '#292929',
                        textAlign: 'center',
                        fontFamily: 'PT Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        justifyContent: 'center',
                        marginTop: '24px'
                    }}>
                        Please search the Job Number or Project Address you wish to add
                    </div>

                    <div style={{ marginInline: '24px' }}>
                        <div style={{ marginBlock: '16px', maxHeight: '25px' }}>
                            <SearchBar searchWidth={'400px'} value={addInputValue} showDropdown={true} allOptions={searchOptions} onOptionSelect={(selectedOption) => { setAddInputValue(selectedOption) }} onChange={handleAddSearch} onKeyDown={handleAddSearchEnterKey} onClick={handleAddSearchSubmit} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
                        <div style={{ width: '74px', height: '25px' }}> <button className={buttonStyles.YesButtonComponentStyling} onClick={handleAddSubmit}>ADD</button> </div>
                        <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}> <button className={buttonStyles.NoButtonComponentStyling} onClick={closeAddModal} >CANCEL</button> </div>
                    </div>

                </div>
            </div>}

            {/* Comment Box Modal */}
            {commentModal && <div className='backGround' onClick={closeCommentModal}>
                <div className='form' onClick={handleFormClick}>

                    <div className='FormRowSection' style={{ marginTop: '24px', marginInline: '24px' }}>
                        <div className='FormInput'>
                            <CommentBox initialData={currentComment} locked={currentLockedStatus} onValueChange={(newComment) => setCurrentComment(newComment)} />
                        </div>
                    </div>
                    <div style={{ fontSize: 14, fontFamily: 'PT Sans', textAlign: 'center', marginBottom: '20px' }}>
                        <div className='FormText' style={{ width: '250px', height: '32px', justifyContent: 'center' }}>
                            NOTE: For hourly rate projects, your comment
                            below will be included on the invoice
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
                        {currentLockedStatus ? (
                            // Render only the Close button when locked
                            <div style={{ width: '74px', height: '25px' }}>
                                <button className={buttonStyles.NoButtonComponentStyling} onClick={closeCommentModal}>CLOSE</button>
                            </div>
                        ) : (
                            // Render Add and Cancel buttons when not locked
                            <>
                                <div style={{ width: '74px', height: '25px' }}>
                                    <button className={buttonStyles.YesButtonComponentStyling} onClick={handleSubmitClick}>ADD</button>
                                </div>
                                <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                                    <button className={buttonStyles.NoButtonComponentStyling} onClick={closeCommentModal}>CANCEL</button>
                                </div>
                            </>
                        )}
                    </div>

                </div>
            </div>}
        </div>
    )

}

export default Timesheets;