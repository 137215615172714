import React from 'react';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import textfieldStyle from "../../GlobalStyles/styles";
import * as Yup from 'yup';
import styles from './ProjectNumPopUp.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';

const ProjectNumPopUp = ({ isVisible, onClose, onSubmit }) => {

    // Form validation schema using Yup
    const validationSchema = Yup.object().shape({
        numberValue: Yup.number()
            .required('A value is required')
            .min(1, 'Value must be greater than or equal to 1')
            .typeError('Please enter a valid number'),
    });

    const formik = useFormik({
        initialValues: {
            numberValue: '', // Initial value for the text field
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values.numberValue);
            onClose();
        },
    });

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} style={{ width: '700px' }} onClick={e => e.stopPropagation()}>
                <form onSubmit={formik.handleSubmit}>
                    <p className={styles.text}>
                        Open existing FBP and save as today's date. Then make the changes you want, save and close
                    </p>
                    <p className={styles.text}>
                        The task's value when added into Planner will be calculated from the FBP - So change the “Fee” on the FBP to reflect the total value of the added tasks.
                    </p>
                    <p className={styles.text} style={{ fontWeight: 'bold' }}>
                        Any changes to comments or billing info is ignored with this process.
                    </p>
                    <p className={styles.text} style={{ fontWeight: 'bold' }}>
                        This does not delete any existing tasks on Planner - beware of duplication
                    </p>

                    <div className={styles.inputSection} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <label className='FilterLabel' htmlFor="projectNumber" style={{ fontSize: '13px', whiteSpace: 'nowrap', marginBottom: '5px' }}>
                            Project Number:
                        </label>
                        <TextField
                            id="projectNumber"
                            sx={textfieldStyle}
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="numberValue"
                            error={Boolean(formik.touched.numberValue && formik.errors.numberValue)}
                            helperText={formik.touched.numberValue && formik.errors.numberValue}
                            fullWidth
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <div style={{ width: '74px', height: '25px' }}>
                            <button type="submit" className={buttonStyles.YesButtonComponentStyling}>
                                SUBMIT
                            </button>
                        </div>
                        <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                            <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProjectNumPopUp;
