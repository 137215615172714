import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Modal from 'react-modal';
import buttonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';

function BookedLeaveTable({ rows, setRows, setIsDataFetched, handleCancelationReload, admin }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const { accounts } = useMsal();
  const account = accounts[0];

  const openModal = (id) => {
    setCurrentId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCancelClick = () => {
    handleCancelationReload(currentId);
    setModalIsOpen(false);
  };

  const customStyles = {
    overlay: {
      zIndex: 10000,
      backgroundColor: 'transparent',
    },
    content: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eaf2f9',
      width: '359px',
      height: '111px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
  };

  console.log('rows: ', rows);

  return (
    <div>
      <table style={{ width: '100%', height: admin ? 'auto' : '300px', overflowY: 'auto' }}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Dates</th>
            <th>Used</th>
            <th>Type</th>
            <th>Length</th>
            {admin && <th>Cancel</th>}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 ? (
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  color: '#4a60a8',
                  fontWeight: 'bold',
                }}
                colSpan={admin ? 6 : 5}
              >
                No information on record.
              </td>
            </tr>
          ) : (
            rows.map((row) => (
              <tr key={row.id}>
                <td className="leftColumnHolidayBookingSection" style={{ fontSize: '12px' }}>
                  <div className={row.status === 'PENDING' ? 'statusPending' : 'statusApproved'} style={{ fontSize: '12px' }}>
                    {row.status}
                  </div>
                </td>
                <td style={{ fontSize: '12px' }}>{row.dates}</td>
                <td style={{ fontSize: '12px' }}>{row.used}</td>
                <td style={{ fontSize: '12px' }}>{row.type}</td>
                <td style={{ fontSize: '12px' }}>{row.length}</td>
                {admin && (
                  <td className="rightColumnHolidayBookingSection">
                    {row.cancel && row.type !== 'Sick Leave' ? (
                      <div className="cancelButton" style={{ fontSize: '12px' }} onClick={() => openModal(row.id)}>
                        CANCEL
                      </div>
                    ) : null}
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ fontSize: 14, fontFamily: 'PT Sans', textAlign: 'center', marginTop: '29px', marginBottom: '20px' }}>
          Are you sure you want to cancel this request?
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button className={buttonStyles.YesButtonComponentStyling} style={{ marginRight: '10px', width: '50px' }} onClick={handleCancelClick}>
            YES
          </button>
          <button className={buttonStyles.NoButtonComponentStyling} onClick={closeModal} style={{ width: '50px' }}>
            NO
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default BookedLeaveTable;