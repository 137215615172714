import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux

// ----------------------------------------------------------------------

let initialState = {
  searchValue: "",
  searchInputValue: "",
  serviceFilter: "All",
  typeFilter: "All",
  serviceOptions: [],
  runnerOptions: [],
  taskOptions: [],
  typeOptions: ["All", "Supplier", "Subcontractor"],
  isSubcontractorProjectsLoading: false,
  supplierListLoading: true,
  subcontractorLoading: true,
  subcontractor: {},
  supplierList: { 'rows': [] },
  subcontractorProjects: [],
  sortColumn: "",
  sortDirection: "asc",
  currentPage: 1,
  newSupplier: {},

  error: null,
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {

    // set filter values in redux
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },

    setServiceFilter(state, action) {
      state.serviceFilter = action.payload;
    },

    setTypeFilter(state, action) {
      state.typeFilter = action.payload;
    },

    setSearchInputValue(state, action) {
      state.searchInputValue = action.payload;
    },

    setSupplierList(state, action) {
      state.supplierList = action.payload;
      state.supplierListLoading = false;
    },

    setServiceOptions(state, action) {
      state.serviceOptions = action.payload;
    },

    setTaskOptions(state, action) {
      state.taskOptions = action.payload;
    },

    setRunnerOptions(state, action) {
      state.runnerOptions = action.payload;
    },

    setSortDirection(state, action) {
      state.sortDirection = action.payload;
    },

    setSortColumn(state, action) {
      state.sortColumn = action.payload;
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    setSubcontractor(state, action) {
      state.subcontractor = action.payload;
    },

    setSubcontractorProjectsLoading(state, action) {
      state.isSubcontractorProjectsLoading = action.payload;
    },

    setSubcontractorProjects(state, action) {
      state.subcontractorProjects = action.payload;
      state.isSubcontractorProjectsLoading = false;
    },

    setSuppliersListLoading(state, action) {
      state.supplierListLoading = action.payload;
    },

    startSubcontractorLoading(state, action) {
      state.subcontractorLoading = action.payload;
    },

    setNewSupplier(state, action) {
      state.newSupplier = action.payload;
    },



    // HAS ERROR
    hasError(state, action) {
      state.isinvSummaryTableLoading = false;
      state.error = action.payload;
    },


  },

});

export const {
  setSearchValue,
  setServiceFilter,
  setTypeFilter,
  setSearchInputValue,
  setSortColumn,
  setSortDirection,
  setCurrentPage,
  hasError
} = suppliersSlice.actions;

// Reducer
export function getSuppliersList(serviceFilter, typeFilter, searchValue, sortColumn, sortDirection, rowsPerPage, page) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(suppliersSlice.actions.setSuppliersListLoading(true));
    try {
      // Construct the query string
      const queryParams = new URLSearchParams({
        serviceFilter,
        typeFilter,
        page,
        rowsPerPage,
        searchValue,
        sortColumn,
        sortDirection
      }).toString();

      const response = await axios.get(`${API_URL}/api/suppliers/pageData?${queryParams}`);
      dispatch(suppliersSlice.actions.setSupplierList(response.data));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function createSupplier(formData) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async (dispatch, getState) => {
    try {
      const response = await axios.post(`${API_URL}/api/suppliers/addNew`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      dispatch(suppliersSlice.actions.setNewSupplier(response.data.data));

      // Get the current state values to use as parameters
      const state = getState().suppliers;
      const { serviceFilter, typeFilter, searchValue, sortColumn, sortDirection, currentPage, rowsPerPage } = state;

      // Call getSuppliersList using the current state values
      dispatch(getSuppliersList(serviceFilter, typeFilter, searchValue, sortColumn, sortDirection, rowsPerPage, currentPage));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function fetchServiceOptions() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async (dispatch) => {
    try {
      const result = await axios.get(`${API_URL}/api/suppliers/serviceOptions`);
      dispatch(suppliersSlice.actions.setServiceOptions(result.data));
    } catch (error) {
      console.error('Error fetching service options:', error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function fetchRunnerOptions() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async (dispatch) => {
    try {
      // Step 1: Fetch runner options from the API
      const result = await axios.get(`${API_URL}/api/projects/runnerOptions?mode=Initials`);

      // Step 2: Transform the data
      console.log(result.data)
      const transformedData = result.data.map(runner => runner);
      transformedData.unshift('All');

      // Step 3: Dispatch the transformed data to the Redux store
      dispatch(suppliersSlice.actions.setRunnerOptions(transformedData));
    } catch (error) {
      // Step 4: Handle errors
      console.error('Error fetching runners:', error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function updateProjectDetails(formData, subcontractorId) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_URL}/api/suppliers/subcontractor/updateProjectDetails`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Refresh subcontractor projects after update
      dispatch(getSubcontractorProjects(subcontractorId));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function deleteSubcontractorTask(taskID, subcontractorId) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_URL}/api/suppliers/subcontractor/deleteTask`, taskID, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Refresh subcontractor projects after update
      dispatch(getSubcontractorProjects(subcontractorId));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function getSubcontractor(subcontractorName) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(suppliersSlice.actions.startSubcontractorLoading());
    try {
      const response = await axios.get(`${API_URL}/api/suppliers/subcontractor?name=${subcontractorName}`);
      const formattedSubcontractor = response.data.data.map(subcontractor => ({
        value: subcontractor.id,
        label: subcontractor.name
      }));
      dispatch(suppliersSlice.actions.setSubcontractor(formattedSubcontractor));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function getSubcontractorProjects(subcontractorId) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(suppliersSlice.actions.setSubcontractorProjectsLoading(true));
    try {
      const response = await axios.get(`${API_URL}/api/suppliers/subcontractor/projectList?subcontractorId=${subcontractorId}`);
      dispatch(suppliersSlice.actions.setSubcontractorProjects(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export default suppliersSlice.reducer;